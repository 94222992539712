import React, {Component} from "react";
import AutoTable from "./autoTable";
import AutoDetail from "./autoDetail";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

//
// Example:
// <AutoSelect
//     itemId={'...'}
//     table={{
//         name: '이름',
//         partition: '파티션',
//         headers: [],
//         searchFields: [],
//         query: [],
//         reverse: true,
//     }}
//     detail={{
//         name: '이름',
//         headers: [],
//     }}
//     onChange={(item)=> {
//
//     }}
// />

class AutoSelect extends Component{

    state = {
        item: {
            id : this.props.itemId,
        },
        showModal: false,
    };

    onSelectItem = (item) => {
        this.state.item = item;
        this.setState({item});
        this.onChange();
        if (this.props.table.onSelectItem){
            this.props.table.onSelectItem(item);
        }
    };

    onDeleteItem = (item) => {
        this.onSelectItem(null);
    };

    onChange = () => {
        if (this.props.onChange){
            if (this.state.item && this.state.item.id){
                this.props.onChange(this.state.item.id)
            }else{
                this.props.onChange(null);
            }
        }
    };

    // table: {
    //     name: '커머스 서비스 카테고리 목록',
    //     partition: 'commercial_service_category',
    //     query: [],
    //     searchFields: ['name'],
    //     headers: [
    //         {title: '이름', field: 'name', type: 'string'}
    //         ]
    // },
    // detail: {
    //     name: '선택된 커머스 서비스 카테고리',
    //     headers: [{title: '이름', field: 'name', type: 'string'}],
    // }

    getTable = () => {
        if (!this.props.table){
            return;
        }
        let name = this.props.table.name;
        if (!name){
            name = this.props.title;
        }

        let partition = this.props.table.partition;
        let headers = this.props.table.headers;
        let searchFields = this.props.table.searchFields;
        let query = this.props.table.query;
        let reverse = this.props.table.reverse;
        let join = this.props.table.join;
        let readOnly = this.props.readOnly;
        if (!partition){
            return null;
        }
        let table = <AutoTable
            name={name}
            partition={partition}
            headers={headers}
            searchFields={searchFields}
            query={query}
            join={join}
            reverse={reverse}
            onSelectItem={(item)=>{
                this.onSelectItem(item);
                this.setState({showModal: false});
            }}
        />;

        table = <>
            <Button className={'btn-block btn-info'} disabled={readOnly} onClick={()=>{this.setState({showModal: true})}}>
                선택하기
            </Button>

            <Modal show={this.state.showModal} onHide={()=>{this.setState({showModal: false})}}>

                <Modal.Body>{table}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{this.setState({showModal: false})}}>
                        닫기
                    </Button>
                </Modal.Footer>
            </Modal>
        </>;

        return table;
    };

    getDetail = () => {
        if (!this.props.detail || !this.state.item){
            return;
        }
        let name = this.props.detail.name;
        let headers = this.props.detail.headers;
        let join = this.props.detail.join;
        let itemId = this.state.item.id;
        let readOnly = this.props.readOnly;

        if (!name){
            name = '상세';
        }
        if (!itemId){
            return;
        }
        headers.forEach(header => {
            header.readOnly = true;
        });
        let detail = <AutoDetail
            join={join}
            readOnly={readOnly}
            key={itemId}
            name={name}
            itemId={itemId}
            headers={headers}
            onDelete={this.onDeleteItem}
        />;
        //this.setState({detail: detail});
        return detail;
    };

    prepare = () => {
        //this.prepareTable();
        //this.prepareDetail();
    };

    componentDidMount() {
        this.prepare();
    }

    render() {
        return (<>
            <div className="mt-2">
                {this.getDetail()}
            </div>
            <div className="mt-2">
                {this.getTable()}
            </div>
        </>);
    }

}

export default AutoSelect;
