import React, {Component} from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import API from "../server/service/api/API";
import AutoList from "./autoList";
import Image from "react-bootstrap/Image";
import AutoSelect from "./autoSelect";
import JoditEditor from "jodit-react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";
import PostcodeField from "./PostcodeField";
import DateTimePickerCustom from "./DateTimePickerCustom";
import Resizer from 'react-image-file-resizer';


const AsyncFunction = (async () => {}).constructor;
const GeneratorFunction = (function* () {}).constructor;

const isAsyncFunction = value => value instanceof AsyncFunction;
const isGeneratorFunction = value => value instanceof GeneratorFunction;


class AutoDetail extends Component{

    // *** EXAMPLE ***
    // <AutoDetail
    // name={'사용자 정보'}
    // itemId={this.getUserId()}
    // headers={[
    //     {title: '이름', field: 'name', type: 'string'},
    //     {title: '성별', field: 'gender', type: 'string', options: ['F', 'M']},
    //     {title: '생일', field: 'birth', type: 'string'},
    //     {title: '생성일', field: 'creation_date', type: 'date', readOnly: true},
    //     {title: '핸드폰', field: 'phone', type: 'string', readOnly: true},
    //     {title: '마케팅 수신 동의', field: 'marketing_agreement', type: 'boolean', readOnly:true},
    //     {title: '탭', type: 'tabs', tabs: {tab1: [
    //        {title: '이름', field: 'name', type: 'string'},
    //        {title: '이름2', field: 'name2', type: 'string'},
    //     ], tab2: []} }
    // ]}
    // onSave={(item) => {
    //     API.auth.updateUser(item.id, item).then((response)=> {
    //     //         if (response.success){
    //     //             alert("성공");
    //     //         }else if (response.error){
    //     //             alert(response.error.message);
    //     //         }
    //     //     })
    // }}
    // />

    state = {
        item: { id : this.props.itemId },
        prev_item: {},
        formRows: []
    };

    errors = {};

    loadForms = (itemId) => {
        let formRows = null;
        if (this.props.headers){
            formRows = Array(this.props.headers.length);
        }
        let headers = this.props.headers;
        let count = 0;
        API.database.getItem(itemId).then((response)=> {
            let item = response.item;
            if (!item){
                //alert('유효하지 않은 item_id: ' + itemId);
                return
            }
            this.setState({
                prev_item: item
            });
            if (!headers){
                // headers 가 없는 경우 !formRows 가 false
                headers = [];
                for (let header of Object.keys(item)){
                    headers.push({
                        title: header,
                        field: header,
                        type: 'string'
                    });
                }
                formRows = Array(headers.length);
            }
            headers.forEach((header, index) => {
                this.getFormRow(item, header).then((formRow)=>{
                    formRows[index] = formRow;
                    count += 1;
                    if (count === formRows.length){
                        this.setState({formRows});
                    }
                })
            })
        });
    };

    loadFormsByItem = (item) => {
        let formRows = null;
        if (this.props.headers){
            formRows = Array(this.props.headers.length);
        }
        let headers = this.props.headers;
        let count = 0;
        this.setState({
            prev_item: item
        });
        if (!headers){
            // headers 가 없는 경우 !formRows 가 false
            headers = [];
            for (let header of Object.keys(item)){
                headers.push({
                    title: header,
                    field: header,
                    type: 'string'
                });
            }
            formRows = Array(headers.length);
        }
        headers.forEach((header, index) => {
            this.getFormRow(item, header).then((formRow)=>{
                formRows[index] = formRow;
                count += 1;
                if (count === formRows.length){
                    this.setState({formRows});
                }
            })
        })
    };


    getFormRow = (item, header) => {
        return this.getFormData(item, header).then((formData) => {
            return <Form.Group key={item.id + this.make_id(30)} as={Row}>
                <Form.Label column sm="2">
                    <p>
                        {header.title}
                    </p>
                    <p style={{fontSize: 10, fontWeight: 400, marginTop: -10, marginBottom: -10}}>
                        {header.field}
                    </p>

                </Form.Label>
                <Col sm="10">
                    {formData}
                </Col>
            </Form.Group>
        });
    };

    putError = (key, message) => {
        this.errors[key] = message
    };

    handleSetItemString = (e) => {
        let item = this.state.item;
        item[e.target.name] = e.target.value;
        if (item[e.target.name] === ''){
            item[e.target.name] = null;
        }
        this.setState({
            item: item
        });
        this.onChange()
    };

    handleSetItemFloat = (e) => {
        let item = this.state.item;
        item[e.target.name] = parseFloat(e.target.value);
        this.setState({
            item: item
        });
        this.onChange()
    };

    handleSetItemBoolean = (e) => {
        let item = this.state.item;
        item[e.target.name] = e.target.checked;
        this.setState({
            item: item
        });
        this.onChange()
    };

    handleSetItemDate = (e) => {
        let item = this.state.item;
        let dateTime = Date.parse(e.target.value) / 1000;
        if (!dateTime){
            this.putError(e.target.name,'날짜 시간 형식이 잘못 되었습니다. [일/월/년, 시:분:초 AM|PM]');
            return;
        }
        this.putError(e.target.name, null);
        item[e.target.name] = dateTime;
        this.setState({
            item: item
        });
        this.onChange()
    };

    handleSetItemDateTime = (field, datetime) => {
        let item = this.state.item;
        item[field] = (+ datetime) / 1000;
        this.setState({
            item: item
        });
        this.onChange()
    };

    handleObject = (field, object) => {
        let item = this.state.item;
        if (object instanceof Array && object.length === 0){
            object = null;
        }
        if (object instanceof Map && Object.keys(object).length === 0){
            object = null;
        }

        item[field] = object;
        this.setState({
            item
        });
        this.onChange();
    };

    compressImageFile = async (imageFile) => {
        console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
        console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

        const quality = window.prompt("품질 (0~100):", '70');
        const maxWidthOrHeight = parseInt(window.prompt("maxWidth:", "1200"));
        return new Promise((resolve => {
            Resizer.imageFileResizer(
                imageFile,
                maxWidthOrHeight,
                10000000,
                'WEBP',
                parseInt(quality),
                0,
                file => {
                    alert(`압축 전 ${(imageFile.size / 1024 / 1024).toFixed(2)} MB \n> 압축 후: ${(file.size / 1024 / 1024).toFixed(2)} MB`);
                    resolve(file);
                },
                'file'
            );
        }));
    }

    handleFileChange = (e) => {
        let files = e.target.files;
        let field = e.target.name;
        if (files && files.length > 0){
            let file = files[0];
            (async ()=>{
                if (window.confirm("이미지를 압축할까요? \n[움직이는 이미지는 지원 안됨]")){
                    file = await this.compressImageFile(file);
                }
                let response = await API.storage.uploadFile(file);
                let fileId = response.file_id;
                let item = this.state.item;
                item[field] = fileId;
                this.setState({
                    item
                });
                this.onChange();
                this.loadFormsByItem(item);
            })();

        }
    };

    handleFileDeleted = (field) => {
        let item = this.state.item;
        item[field] = null;
        this.setState({
            item
        });
        this.onChange();
        this.loadFormsByItem(item);
    };

    handleHtmlChange = (field, value) => {
        API.storage.uploadB64(value, true).then((response) => {
            let fileId = response.file_id;
            let item = this.state.item;
            item[field] = fileId;
            this.setState({
                item
            });
            this.onChange();
        });
    };

    getFormData = (item, header) => {
        return new Promise(resolve => {
            let field = header.field;
            let title = header.title;
            let type = header.type;
            let readOnly = header.readOnly;
            let raw = item[field];
            let options = header.options;
            let transformer = header.transformer;
            let required = header.required;
            if (header.defaultValue && !raw){
                raw = header.defaultValue;
            }

            if (!readOnly){
                readOnly = false;
            }

            if (type === 'string'){
                if (options && options.length > 0){
                    let formData = <Form.Control
                        name={field}
                        as="select"
                        onChange={this.handleSetItemString}
                        defaultValue={raw}
                        custom>
                        <option value={""}>{""}</option>
                        {options.map((option)=> {
                            return <option value={option}>{option}</option>
                        })}
                    </Form.Control>;
                    this.handleSetItemString({
                        target: {
                            name: field,
                            value: raw
                        }
                    });
                    resolve(formData);
                }else {
                    let fromData = <Form.Control
                        name={field} type="text"
                        onChange={this.handleSetItemString}
                        placeholder={title}
                        defaultValue={raw}
                        readOnly={readOnly}/>;
                    this.handleSetItemString({
                        target: {
                            name: field,
                            value: raw
                        }
                    });
                    resolve(fromData);
                }
            }else if (type === 'textarea') {
                let fromData = <Form.Control
                    name={field} type="textarea"
                    as="textarea" rows="10"
                    onChange={this.handleSetItemString}
                    placeholder={title}
                    defaultValue={raw}
                    readOnly={readOnly}/>;
                this.handleSetItemString({
                    target: {
                        name: field,
                        value: raw
                    }
                });
                resolve(fromData);
            }else if(type === 'json'){
                let fromData = <Form.Control
                    name={field} type="textarea"
                    as="textarea" rows="10"
                    onChange={(e)=>{
                        let raw = e.target.value;
                        this.handleSetItemString({
                            target: {
                                name: field,
                                value: JSON.stringify(raw, null, 2)
                            }
                        });
                    }}
                    placeholder={title}
                    defaultValue={JSON.stringify(raw, null, 2)}
                    readOnly={readOnly}/>;
                this.handleSetItemString({
                    target: {
                        name: field,
                        value: JSON.stringify(raw, null, 2)
                    }
                });
                resolve(fromData);
            }else if (type === 'number') {
                let fromData = <Form.Control
                    name={field} type="number"
                    onChange={this.handleSetItemFloat}
                    placeholder={title}
                    defaultValue={raw}
                    readOnly={readOnly}/>;
                this.handleSetItemFloat({
                    target: {
                        name: field,
                        value: raw
                    }
                });
                resolve(fromData);
            }else if (type === 'date'){
                let dateTimeString = new Date(raw * 1000).toLocaleString('en');
                if (!dateTimeString || dateTimeString === 'Invalid Date'){
                    dateTimeString = '';
                }else{
                    this.handleSetItemDate({
                        target: {
                            name: field,
                            value: dateTimeString
                        }
                    });
                }
                let fromData = <Form.Control
                    name={field} type="text"
                    placeholder={'일/월/년, 시:분:초 AM|PM'}
                    onChange={this.handleSetItemDate}
                    defaultValue={dateTimeString}
                    readOnly={readOnly}/>;

                resolve(fromData);
            }else if (type === 'datetimepicker'){
                let datetime = null;
                if (raw){
                    datetime = new Date(raw * 1000);
                    this.handleSetItemDate({
                        target: {
                            name: field,
                            value: datetime
                        }
                    });
                }
                if (this.state.item[field]){
                    datetime = new Date(this.state.item[field] * 1000);
                }
                // let fromData = <Form.Control
                //     name={field} type="text"
                //     placeholder={'일/월/년, 시:분:초 AM|PM'}
                //     onChange={this.handleSetItemDate}
                //     defaultValue={dateTimeString}
                //     readOnly={readOnly}/>;
                let formData = <DateTimePickerCustom
                    name={field}
                    onChange={(datetime) => {
                        this.handleSetItemDateTime(field, datetime);
                    }}
                    value={datetime}
                />;
                resolve(formData);
            } else if (type === 'image'){
                let width = header.width;
                let height = header.height;
                if (!width){
                    width = 256
                }
                if (!height){
                    height = 256
                }
                let form = <Form.File
                        className={'mb-2'}
                        label="파일을 선택해주세요"
                        custom
                        on
                        name={field}
                        onChange={this.handleFileChange}
                    />;
                if (raw){
                    API.storage.downloadB64(raw).then((data) => {

                        let fileId = raw;
                        let item = this.state.item;
                        item[field] = fileId;
                        this.setState({
                            item
                        });
                        this.onChange();

                        resolve(<Form>{form}
                            <p>
                                { data.file_b64 ? `${(data.file_b64.length / 1024 / 1024).toFixed(2)}MB` : null}
                            </p>
                            <Image src={`data:image/gif;base64,${data.file_b64}`} width={width} height={height} rounded fluid />
                            <Button onClick={()=>this.handleFileDeleted(field)} className={'btn btn-danger btn-block mt-2'}>파일 삭제</Button>
                        </Form>);
                    });
                }else{
                    resolve(<Form>{form}</Form>)
                }

            }else if (type === 'boolean'){
                let formData = <Form.Check
                    name={field}
                    custom
                    id={`custom-${this.make_id(10)}`}
                    label={title}
                    defaultChecked={raw}
                    onChange={this.handleSetItemBoolean}
                />;
                this.handleSetItemBoolean({
                    target: {
                        name: field,
                        checked: raw
                    }
                });
                resolve(formData);
            }else if (type === 'list'){
                let formData = <AutoList
                    name={field}
                    items={raw}
                    headers={header.headers}
                    onChange={(items) => this.handleObject(field, items)}/>;
                resolve(formData);
            }else if (type === 'dict'){
                if (!raw){
                    raw = {};
                }
                let formData = <AutoDetail
                    item={raw}
                    headers={header.headers}
                    onChange={(item) => this.handleObject(field, item)}
                />;
                this.handleObject(field, raw);
                resolve(formData);
            }else if (type === 'item'){
                let formData = <AutoSelect
                    readOnly={readOnly}
                    itemId={raw}
                    table={header.table}
                    detail={header.detail}
                    onChange={(item) => {
                        this.handleObject(field, item);
                        if (header.onSelect){
                            header.onSelect(item);
                        }
                    }}
                />;
                this.handleObject(field, raw);

                resolve(formData);
            }else if (type === 'html'){

                const config = {
                    readonly: false, // all options from https://xdsoft.net/jodit/doc/,
                    uploader: {
                        insertImageAsBase64URI: true
                    },
                };

                if (raw){
                    API.storage.downloadB64(raw, true).then((response) => {
                        let fileB64 = response.file_b64;
                        let codeEditor = <Form>
                            <JoditEditor
                                value={fileB64}
                                config={config}
                                tabIndex={1} // tabIndex of textarea
                                onChange={content => {
                                    this.handleHtmlChange(field, content);
                                }}
                            />
                        </Form>;
                        this.handleHtmlChange(field, fileB64);
                        resolve(codeEditor);
                    });
                }else{
                    let codeEditor = <Form>
                        <JoditEditor
                            value={""}
                            config={config}
                            tabIndex={1} // tabIndex of textarea
                            onChange={content => {
                                this.handleHtmlChange(field, content);
                            }}
                        />
                        {/*<Button variant={'primary mt-2 btn-block'}>미리보기</Button>*/}
                    </Form>;
                    this.handleHtmlChange(field, "");
                    resolve(codeEditor);
                }
            }else if (type === 'python'){
                if (!raw && header.defaultValue){
                    raw = header.defaultValue;
                }
                let editor = <AceEditor
                    mode="python"
                    theme="github"
                    onChange={value => {
                        this.handleSetItemString({
                            target: {
                                name: field,
                                value: value
                            }
                        });
                    }}
                    value={raw}
                    name={field}
                    width={'100%'}
                    editorProps={{ $blockScrolling: true }}
                />;
                resolve(editor);
            }else if (type === 'postcode'){
                let addressField = header.addressField;
                let fromData = <><PostcodeField
                    postcodeField={field}
                    postcode={raw}
                    address={item[addressField]}
                    onChange={(postcode, address)=>{
                        this.handleSetItemString({
                            target: {
                                name: field,
                                value: postcode
                            }
                        });
                        this.handleSetItemString({
                            target: {
                                name: addressField,
                                value: address
                            }
                        }, ()=>{
                            this.loadFormsByItem(this.state.item);
                        });
                    }}
                /></>;
                this.handleSetItemString({
                    target: {
                        name: field,
                        value: raw
                    }
                });
                this.handleSetItemString({
                    target: {
                        name: addressField,
                        value: item[addressField]
                    }
                });
                resolve(fromData);
            }else if (type === 'transform'){
                if (isAsyncFunction(transformer)){
                    (async ()=>{
                        let value = await transformer(raw);
                        resolve(value);
                    })();
                }else{
                    let value = transformer(raw);
                    resolve(value);
                }
            }else{
                resolve(raw);
            }
        })
    };

    onSave = () => {
        let hasError = false;
        Object.values(this.errors).forEach((value) => {
            if (value){
                alert(value);
                hasError = true;
            }
        });
        if (!hasError && this.props.onSave){
            this.props.onSave(this.state.item);
            if (this.state.item.id) {
            }
        }
    };

    onDelete = () => {
        if (this.props.onDelete){
            this.props.onDelete(this.state.item)
        }
    };

    onChange = () => {
        if (this.props.onChange){
            this.props.onChange(this.state.item)
        }
    };

    loadCardFooter = () => {
        let footerButtons = [];
        if (this.props.onSave){
            footerButtons.push(<Button onClick={this.onSave} className={'btn btn-success'}>확인</Button>);
        }
        if (this.props.onDelete){
            footerButtons.push(<Button onClick={this.onDelete} disabled={this.props.readOnly} className={'btn btn-danger'}>삭제</Button>)
        }
        if (footerButtons.length > 0){
            let cardFooter = <div className="card-footer py-4 text-right">
                {footerButtons}
            </div>;
            this.setState({cardFooter});
        }else{
            this.setState({cardFooter: null});
        }

    };

    loadCardHeader = () => {
        let cardHeader = <div className="card-header border-0">
            <InputGroup style={{textAlign: 'right'}}>
                <h3>{this.props.name}</h3>
            </InputGroup>
        </div>;
        if (this.props.name){
            this.setState({cardHeader})
        }else{
            this.setState({cardHeader: null})
        }
    };

    prepare = () => {
        let itemId = this.props.itemId;
        let item = this.props.item;
        if (itemId){
            this.loadForms(itemId);
        }else if(item){
            this.loadFormsByItem(item)
        }else{
            this.loadFormsByItem({})
        }
        this.loadCardHeader();
        this.loadCardFooter();
    };

    componentDidMount() {
        this.prepare();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.state = {
            item: { id : this.props.itemId },
            prev_item: {},
            formRows: []
        };
        this.prepare();
    }

    make_id = (length) => {
        let result           = '';
        let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    render() {
        return (
            <div className="card mt-2">
                {this.state.cardHeader}
                <div className="card-body">
                    {this.state.formRows}
                </div>
                {this.state.cardFooter}
            </div>
        );
    }

}

export default AutoDetail;
