
import React, {Component} from "react";
import AutoTable from "../../../components/autoTable";
import Sidebar from "../sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import WithdrawalRecordDetail from "./withdrawalRecordDetail";


class WithdrawalRecords extends Component{

    static path = '/withdrawalrecords';

    render() {
        return (
            <>
                <Sidebar history={this.props.history}/>

                <Breadcrumb>
                    <Breadcrumb.Item active>회원 관리</Breadcrumb.Item>
                    <Breadcrumb.Item href={window.location.href}>탈퇴한 기록</Breadcrumb.Item>
                </Breadcrumb>

                <Container fluid className={'mt-4 mb-4'}>
                    <Row>
                        <Col>
                            <AutoTable
                                name={'탈퇴 목록'}
                                partition={'withdrawal_record'}
                                searchFields={['name', 'email']}
                                headers={[
                                    {title: '이름', field: 'user.item.name', type: 'string'},
                                    {title: '전화번호', field: 'user.item.email', type: 'string'},
                                    {title: '사유', field: 'reason', type: 'string'},
                                    {title: '탈퇴일', field: 'creation_date', type: 'date'},
                                    {title: '가입일', field: 'user.item.creation_date', type: 'date'},
                                ]}
                                reverse={true}
                                onShowDetail={(item) => {
                                    this.props.history.push(WithdrawalRecordDetail.path.split(':')[0] + item.id);
                                }}
                            />
                        </Col>
                    </Row>
                </Container>

            </>
        );
    }

}

export default WithdrawalRecords;
