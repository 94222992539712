import React, {Component} from "react";
import SignIn from "../components/signIn";
import API from "../server/service/api/API";
import Users from "./dashboard/member/users";


class Login extends Component {

    static path = '/login';

    onSignIn = (email, password) => {
        API.auth.login(email, password).then((data) => {
            if (data.session_id) {
                this.props.history.push(Users.path);
            }else {
                alert(JSON.stringify(data));
            }
        });
    };

    componentDidMount() {
        API.auth.isLogin().then((loggedIn) => {
            if (loggedIn){
                this.props.history.push(Users.path);
            }
        })
    }

    render() {
        return (
            <div>
                <SignIn onSignIn={this.onSignIn} onVerify={this.onVerify} />

            </div>
        );
    }
}

export default Login;
