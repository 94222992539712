


import React, {Component} from "react";
import AutoTable from "../../../components/autoTable";
import Sidebar from "../sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import OrderDetail from "./OrderDetail";
import API from "../../../server/service/api/API";
import Config from "../../../server/Config";
import Card from "react-bootstrap/Card";
import CardBody from "reactstrap/es/CardBody";
import CardHeader from "reactstrap/es/CardHeader";
import Util from "../../../util/util";
import ExcelToJson from "../../../components/ExcelToJson";
import SendAlimtalk from "../../../components/SendAlimtalk";
import ExportExcelButton from "../../../components/ExportExcelButton";
import {Modal, ModalBody} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";


class Orders extends Component{

    static path = '/orders';

    state = {
        topCards: null,
        showAlimtalk: false,
        phone_numbers: [],
        templates: [],
        excelButton: null,
        showExcelModal: false
    };

    componentDidMount() {
        this.fetchProductionCards();
    }

    productCache = {};

    getItem = async (itemId) => {
        if (this.productCache[itemId]){
            return this.productCache[itemId];
        }
        let item = await API.database.getItem(itemId);
        this.productCache[itemId] = item;
        return item;
    };

    fetchProductionCards = async () => {
        // let today = new Date();
        // let dd = String(today.getDate()).padStart(2, '0');
        // let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        // let yyyy = today.getFullYear();
        //
        // let todayString = mm + '/' + dd + '/' + yyyy;
        // let startDate = Date.parse(todayString);
        // startDate = +startDate;
        // alert(startDate);

        let newOrders = API.database.generateItems('new_order', [{
            condition: 'eq',
            field: 'status',
            value: 'standby',
            option: 'and'
        }]);
        let products = {};
        for await (let newOrder of newOrders){
            let product_counts = newOrder.product_counts;
            for (let product_count of product_counts){
                let product_id = product_count.product_id;
                let count = product_count.count;
                let product = await this.getItem(product_id);
                product = product.item;
                if (product.is_old){
                    for (let product_name in product.names){
                        let count = product.names[product_name];
                        if (products[product_name]){
                            products[product_name] += count
                        }else{
                            products[product_name] = count;
                        }
                    }
                }else{
                    if (products[product.name]){
                        products[product.name] += count;
                    }else{
                        products[product.name] = count;
                    }
                }
            }
        }
        let cards = Object.keys(products).map(name => {
            let count = products[name];
            return <Col xs={12} sm={6} md={4} lg={3} className={'mt-2 mb-2'}>
                <Card>
                    <CardHeader>
                        ({name}) 생산량
                    </CardHeader>
                    <CardBody>
                        {count.toLocaleString()}개
                    </CardBody>
                </Card>
            </Col>;
        });
        this.setState({
            topCards: cards
        })
    };

    render() {
        let orderHeaders = [
            // {title: '회원이름', field: 'user.name', type: 'string'},
            // {title: '회원핸드폰', field: 'user.phone', type: 'string'},
            // {title: '이메일', field: 'user.email', type: 'string'},
            // {title: '상태', field: 'status', type: 'string', options: ['standby', 'shipping', 'complete']},
            {title: '이름', field: 'name', type: 'string'},
            {title: '금액', field: 'price', type: 'number'},
            {title: '유통사', field: 'vendor', type: 'string'},
            {title: '주문자', field: 'shipping_info.buyer_name', type: 'string'},
            {title: '핸드폰', field: 'shipping_info.buyer_tel', type: 'string'},
            {title: '체험판', field: 'is_trial', type: 'boolean'},
            {title: 'ZIP', field: 'shipping_info.buyer_postcode', type: 'string'},
            {title: '주소', field: 'shipping_info.buyer_addr_detail', type: 'string'},
            {title: '운송장', field: 'shipping_number', type: 'string'},
            {title: '생성일', field: 'creation_date', type: 'date'}
        ];
        let join = {
            user_id: 'user'
        };

        let transformItem = async (item) => {
            let items = [];
            let product_counts = item.product_counts;
            if (!product_counts){
                return items;
            }
            let product_groups = await Util.getSplitedProductNames(product_counts);
            for (let product_group of product_groups) {
                let product_name = product_group.products_name;
                let box_type = product_group.box_type.name;
                let newItem = {
                    '예약구분': '',
                    '집하예정일': '',
                    '받는분성명': item.shipping_info.buyer_name + '/' + item.id,
                    '받는분전화번호': item.shipping_info.buyer_tel,
                    '받는분기타연락처': item.shipping_info.buyer_tel,
                    '받는분우편번호': item.shipping_info.buyer_postcode,
                    '받는분주소(전체, 분할)': item.shipping_info.buyer_addr_road + ' ' + item.shipping_info.buyer_addr_detail,
                    '운송장번호': '',
                    '고객주문번호': '',
                    '품목명': product_name,
                    '박스수량': '1',
                    '박스타입': box_type,
                    '기본운임': '신용',
                    '배송메시지1': '',
                    '배송메시지2': '',
                    '배송메시지3': '',
                };
                items.push(newItem);
            }
            return items;
        };

        let actions = [
            {name: '상품준비중', handler: (item_ids)=>{
                    let pairs = {};
                    for (let item_id of item_ids){
                        pairs[item_id] = {
                            'status': 'standby'
                        }
                    }
                    API.database.batchUpdateItems(pairs).then(data=>{
                        window.location.reload();
                    });
                }},
            {name: '배송중', handler: (item_ids)=>{
                    let pairs = {};
                    for (let item_id of item_ids){
                        pairs[item_id] = {
                            'status': 'shipping'
                        }
                    }
                    API.database.batchUpdateItems(pairs).then(data=>{
                        window.location.reload();
                    });
                }},
            {name: '배송완료', handler: (item_ids)=>{
                    let pairs = {};
                    for (let item_id of item_ids){
                        pairs[item_id] = {
                            'status': 'complete'
                        }
                    }
                    API.database.batchUpdateItems(pairs).then(data=>{
                        window.location.reload();
                    });
                }},
            {name: '선택된 결제 환불', handler:async (order_ids)=>{
                    for (let order_id of order_ids){
                        await API.logic.runFunction(Config.functionName, {
                            cmd: 'payment.cancel_payment',
                            order_id: order_id,
                            reason: '어드민 취소'
                        }).then((data)=>{
                            return API.database.updateItem(order_id, {
                                status: 'cancelled'
                            });
                        });
                    }
                    alert("완료되었습니다.");
                    window.location.reload();
                }},
            {name: '선택된 결제 삭제', handler:async (order_ids)=>{
                    if (!window.confirm("정말 삭제하시겠습니까? 환불은 진행되지 않으며, 되돌릴 수 없습니다.")){
                        return;
                    }
                    API.database.batchDeleteItems(order_ids).then(data=>{
                        window.location.reload();
                    });
                    alert("완료되었습니다.");
                    window.location.reload();
                }},
            {
                name: '선택 알림톡 전송', itemHandler: async (orders) => {
                    let phone_numbers = orders.map(order => {
                        return order.shipping_info.buyer_tel;
                    });

                    const getTemplates = async () => {
                        let templates = await API.logic.runFunction(Config.functionName, {
                            cmd: 'notification.get_templates'
                        });
                        return templates;
                    };
                    const templates = await getTemplates();

                    this.setState({
                        showAlimtalk: true,
                        templates, phone_numbers
                    })

                }
            }, {
                name: '선택 엑셀 출력', itemHandler: async (orders) => {
                    this.setState({
                        excelButton: <ExportExcelButton transformItem={transformItem} partition={'new_order'} items={orders}/>,
                        showExcelModal: true,
                    })
                }
            }
        ];


        return (
            <>
                <Sidebar history={this.props.history}/>

                <Breadcrumb>
                    <Breadcrumb.Item active>판매 관리</Breadcrumb.Item>
                    <Breadcrumb.Item href={window.location.href}>주문 목록</Breadcrumb.Item>
                </Breadcrumb>

                <Modal show={this.state.showExcelModal} onHide={()=>{
                    this.setState({
                        showExcelModal: false
                    })
                }}>
                    <ModalHeader>
                        아래 버튼을 눌러 엑셀을 출력하세요.
                    </ModalHeader>
                    <ModalBody>
                        {this.state.excelButton}
                    </ModalBody>
                </Modal>

                <SendAlimtalk show={this.state.showAlimtalk}
                              phone_numbers={this.state.phone_numbers}
                              templates={this.state.templates}
                              onClose={()=>{
                                  this.setState({
                                      showAlimtalk: false
                                  })
                              }}
                />

                <Container fluid className={'mt-4 mb-4'}>

                    <Row>
                        {this.state.topCards}
                    </Row>

                    <Row className={'mb-2'}>
                        <Col>
                            <ExcelToJson callback={async (json) => {
                                let new_orders_to_update = {};
                                json.forEach(item => {
                                    try {
                                        let name_and_new_order_id = item['받는분'];
                                        let shipping_number = item['운송장번호'];
                                        let new_order_id = name_and_new_order_id.split('/')[1];
                                        if (!shipping_number){
                                            return;
                                        }
                                        if (!new_order_id){
                                            return;
                                        }
                                        new_orders_to_update[new_order_id] = {
                                            shipping_number: shipping_number
                                        }
                                    }catch (e) {

                                    }
                                });
                                await API.database.batchUpdateItems(new_orders_to_update);
                                await API.logic.runFunction(Config.functionName, {
                                    'cmd': 'whatssub.update_shipping_numbers',
                                    'order_pairs': new_orders_to_update
                                });
                                alert("등록이 완료되었습니다.");
                                window.location.reload();
                            }}/>
                        </Col>
                    </Row>

                    <Tabs defaultActiveKey="standby">
                        <Tab eventKey="standby" title="상품 준비중">
                            <Row>
                                <Col>
                                    <AutoTable
                                        name={'결제 목록'}
                                        partition={'new_order'}
                                        headers={orderHeaders}
                                        query={[
                                            {condition: 'eq', field: 'status', value: 'standby', option: 'and'}
                                        ]}
                                        join={join}
                                        reverse={true}
                                        onShowDetail={(item) => {
                                            this.props.history.push(OrderDetail.path.split(':')[0] + item.id);
                                        }}
                                        actions={actions}
                                        transformItem={transformItem}
                                        onClickCreate={()=>{
                                            this.props.history.push(OrderDetail.path.split(':')[0])
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Tab>

                        <Tab eventKey="shipping" title="배송중">
                            <Row>
                                <Col>
                                    <AutoTable
                                        name={'결제 목록'}
                                        partition={'new_order'}
                                        headers={orderHeaders}
                                        query={[
                                            {condition: 'eq', field: 'status', value: 'shipping', option: 'and'}
                                        ]}
                                        join={join}
                                        reverse={true}
                                        onShowDetail={(item) => {
                                            this.props.history.push(OrderDetail.path.split(':')[0] + item.id);
                                        }}
                                        actions={actions}
                                        transformItem={transformItem}
                                    />
                                </Col>
                            </Row>
                        </Tab>

                        <Tab eventKey="complete" title="배송완료">
                            <Row>
                                <Col>
                                    <AutoTable
                                        name={'결제 목록'}
                                        partition={'new_order'}
                                        headers={orderHeaders}
                                        query={[
                                            {condition: 'eq', field: 'status', value: 'complete', option: 'and'}
                                        ]}
                                        join={join}
                                        reverse={true}
                                        onShowDetail={(item) => {
                                            this.props.history.push(OrderDetail.path.split(':')[0] + item.id);
                                        }}
                                        actions={actions}
                                        transformItem={transformItem}
                                    />
                                </Col>
                            </Row>
                        </Tab>

                        <Tab eventKey="cancelled" title="취소됨">
                            <Row>
                                <Col>
                                    <AutoTable
                                        name={'결제 목록'}
                                        partition={'new_order'}
                                        headers={orderHeaders}
                                        query={[
                                            {condition: 'eq', field: 'status', value: 'cancelled', option: 'and'}
                                        ]}
                                        join={join}
                                        reverse={true}
                                        onShowDetail={(item) => {
                                            this.props.history.push(OrderDetail.path.split(':')[0] + item.id);
                                        }}
                                        actions={actions}
                                        transformItem={transformItem}
                                    />
                                </Col>
                            </Row>
                        </Tab>

                    </Tabs>

                </Container>

            </>
        );
    }

}

export default Orders;
