import React, {Component} from "react";
import AutoTable from "../../../components/autoTable";
import Sidebar from "../sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AutoDetail from "../../../components/autoDetail";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Users from "./users";
import API from "../../../server/service/api/API";
import UserOrderDetail from "./userOrderDetail";


class UserDetail extends Component{

    static path = '/user/detail/:user_id';

    state = {

    };

    constructor(props){
        super(props);
    };

    getUserId = () => {
        return this.props.match.params.user_id;
    };

    componentDidMount() {

    }

    render() {
        return (
            <>
                <Sidebar history={this.props.history}/>

                <Breadcrumb>
                    <Breadcrumb.Item active>회원 관리</Breadcrumb.Item>
                    <Breadcrumb.Item href={Users.path}>회원 목록</Breadcrumb.Item>
                    <Breadcrumb.Item href={window.location.href}>상세</Breadcrumb.Item>
                </Breadcrumb>

                <Container fluid className={'mt-4 mb-4'}>

                    <Tabs defaultActiveKey="user">
                        <Tab eventKey="user" title="사용자 정보">
                            <Row>
                                <Col className={'col-12 mt-4'}>
                                    <AutoDetail
                                        name={'사용자 정보'}
                                        itemId={this.getUserId()}
                                        headers={[
                                            {title: '생성일', field: 'creation_date', type: 'date', readOnly: true},
                                            {title: '마일리지', field: 'mileage', type: 'number'},
                                            {title: '이름', field: 'name', type: 'string'},
                                            {title: '이메일', field: 'email', type: 'string'},
                                            {title: '성별', field: 'gender', type: 'string', options: ['male', 'female']},
                                            {title: '생일', field: 'birth', type: 'string'},
                                            {title: '핸드폰', field: 'phone', type: 'string'},
                                            {title: '기기정보', field: 'device_info', type: 'dict', headers: [
                                                    {title: '웹 브라우저', field: 'web_browser', type: 'string'},
                                                    {title: '사용 함수명', field: 'function_name', type: 'string'}
                                                ]},
                                            {title: '로그인 방식', field: 'login_method', type: 'string'},
                                            {title: '카카오 로그인 정보', field: 'kakao_account', type: 'dict', headers: [
                                                    {title: '성별', field: 'gender', type: 'string'},
                                                    {title: '프로필', field: 'profile', type: 'dict', headers: [
                                                            {title: '핸드폰', field: 'gender', type: 'string'},
                                                        ]}
                                                ]},
                                            {title: '마케팅 수신 동의', field: 'marketing_agreement', type: 'boolean', readOnly:true}
                                        ]}
                                        onSave={(item) => {
                                            API.auth.updateUser(item.id, item).then((response)=> {
                                                if (response.success){
                                                    alert("성공");
                                                }else if (response.error){
                                                    alert(response.error.message);
                                                }
                                            })
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Tab>

                        <Tab eventKey="cardList" title="결제 목록">
                            <Row>
                                <Col className={'col-12 mt-4'}>
                                    <AutoTable
                                        name={'결제 목록'}
                                        partition={'new_order'}
                                        headers={[
                                            {title: '상태', field: 'status', type: 'string'},
                                            {title: '이름', field: 'name', type: 'string'},
                                            {title: '금액', field: 'price', type: 'number'},
                                            {title: '배송지', field: 'shipping_info.buyer_addr_road', type: 'string'},
                                            {title: '배송지 상세', field: 'shipping_info.buyer_addr_detail', type: 'string'},
                                            {title: '생성일', field: 'creation_date', type: 'date'}
                                        ]}
                                        query={[
                                            {condition: 'eq', field: 'user_id', value: this.getUserId(), option: 'and'}
                                        ]}
                                        onShowDetail={(item) => {
                                            this.props.history.push(UserOrderDetail.path.split(':')[0] + item.id);
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Tab>

                    </Tabs>

                </Container>

            </>
        );
    }

}

export default UserDetail;
