import React, {Component} from "react";
import Sidebar from "../sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import AutoDetail from "../../../components/autoDetail";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import API from "../../../server/service/api/API";
import Products from "./Products";
import Col from "react-bootstrap/Col";
import SubscriptionLevels from "./SubscriptionLevels";
import Reviews from "./Reviews";


export default class ReviewDetail extends Component{

    static path = '/review/detail/:item_id';
    static creation_path = ReviewDetail.path.split(':')[0];

    state = {

    };

    constructor(props){
        super(props);
    };

    getItemId = () => {
        return this.props.match.params.item_id;
    };

    componentDidMount() {

    }

    render() {
        return (
            <>
                <Sidebar history={this.props.history}/>

                <Breadcrumb>
                    <Breadcrumb.Item active>판매 관리</Breadcrumb.Item>
                    <Breadcrumb.Item href={Reviews.path}>리뷰 목록</Breadcrumb.Item>
                    <Breadcrumb.Item href={window.location.href}>상세</Breadcrumb.Item>
                </Breadcrumb>

                <Container fluid className={'mt-4 mb-4'}>

                    <Row>
                        <Col>
                            <AutoDetail
                                name={'정보'}
                                itemId={this.getItemId()}
                                headers={[
                                    {title: '제목', field: 'title', type: 'string'},
                                    {title: '작성자', field: 'owner_name', type: 'string'},
                                    {title: '프로필', field: 'profile_image_id', type: 'image'},
                                    {title: '별점', field: 'start_rating', type: 'number'},
                                    {title: '좋아요', field: 'likes', type: 'number'},
                                    {title: '내용', field: 'content', type: 'textarea'},
                                    {title: '이미지', field: 'image_id', type: 'image'},
                                    {title: '생성일', field: 'creation_date', type: 'date'},
                                ]}
                                onSave={(item) => {
                                    if (this.getItemId()){
                                        API.database.updateItem(item.id, item).then(data => {
                                            if (data.success) {
                                                alert("성공!");
                                            }
                                        });
                                    }else{
                                        API.database.createItem('new_review', item).then(data => {
                                            if (data.item_id) {
                                                alert("성공!");
                                            }
                                        });
                                    }

                                }}
                            />
                        </Col>
                    </Row>

                </Container>

            </>
        );
    }

}
