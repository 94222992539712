import 'date-fns';
import React, {useEffect} from 'react';
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from '@material-ui/pickers';
import "moment/locale/ko";
import moment from "moment";

moment.locale("ko");

export default function NextDatePicker(props) {
    // The first commit of Material-UI
    const [selectedDate, setSelectedDate] = React.useState(props.next_payment_date);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        let timestamp = +(new Date(date)) / 1000;
        props.onChange(timestamp);
    };

    useEffect(()=>{

    }, []);

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
                style={{
                    width: '100%',
                }}
                minDateMessage={"오늘보다 이후 날짜를 입력해주세요."}
                invalidDateMessage={"날짜 형식에 맞게 입력해주세요."}
                autoOk={true}
                cancelLabel={"취소"}
                okLabel={"확인"}
                openTo={"date"}
                disablePast={true}
                id="date-picker-dialog"
                label="다음 결제 예정일"
                format="yyyy년 MM월 DD일"
                value={selectedDate}
                disableToolbar={true}
                showTodayButton={true}
                todayLabel={"오늘"}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    'aria-label': '결제 예정일 변경',
                }}
            />
        </MuiPickersUtilsProvider>
    );
}
