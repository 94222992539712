import React, {Component} from "react";
import Sidebar from "../sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AutoDetail from "../../../components/autoDetail";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import API from "../../../server/service/api/API";
import Orders from "./Orders";
import Subscriptions from "./Subscriptions";


class SubscriptionDetail extends Component{

    static path = '/subscription/:item_id';

    state = {

    };

    constructor(props){
        super(props);
    };

    getItemId = () => {
        return this.props.match.params.item_id;
    };

    componentDidMount() {

    }

    render() {
        return (
            <>
                <Sidebar history={this.props.history}/>

                <Breadcrumb>
                    <Breadcrumb.Item active>회원 관리</Breadcrumb.Item>
                    <Breadcrumb.Item href={Subscriptions.path}>정기 구독 목록</Breadcrumb.Item>
                    <Breadcrumb.Item href={window.location.href}>상세 정보</Breadcrumb.Item>
                </Breadcrumb>

                <Container fluid className={'mt-4 mb-4'}>

                    <Row>
                        <Col className={'col-12 mt-4'}>
                            <AutoDetail
                                name={'상세 정보'}
                                itemId={this.getItemId()}
                                headers={[
                                    {title: '생성일', field: 'creation_date', type: 'date', readOnly: true},
                                    {title: '중단일', field: 'disabled_date', type: 'date', readOnly: true},
                                    {title: '구독중', field: 'enabled', type: 'boolean'},
                                    {title: '주기(주)', field: 'weeks', type: 'number'},
                                    {title: '다음결제일', field: 'next_payment_date', type: 'datetimepicker'},
                                    {title: '결제 중지일', field: 'disabled_date', type: 'datetimepicker'},

                                    {title: '체험판', field: 'is_trial', type: 'boolean', readOnly: true},
                                    {title: '해지이유', field: 'reason', type: 'string', readOnly: true},
                                    {title: '추천인', field: 'recommender_email', type: 'string'},

                                    {title: '주문수', field: 'order_count', type: 'number', readOnly: true},

                                    {title: '배송정보', field: 'shipping_info', type: 'dict', headers: [
                                            {title: '주문자', field: 'buyer_name', type: 'string'},
                                            {title: '핸드폰 번호', field: 'buyer_tel', type: 'string'},
                                            {title: '주소', field: 'buyer_postcode', type: 'postcode',
                                                addressField: 'buyer_addr_road'},
                                            {title: '배송지 상세', field: 'buyer_addr_detail', type: 'string'},
                                        ]},

                                    {title: '결제 스킵', field: 'skip_payment', type: 'boolean'},
                                    {title: '결제 수단', field: 'payment_method_id', type: 'item',
                                        table: {
                                            name: '결제수단',
                                            partition: 'payment_method',
                                            headers: [
                                                {title: '종류', field: 'type', type: 'string'},
                                            ],
                                            searchFields: [],
                                            query: [
                                                {condition: 'eq', field: 'admin', value: 1, option: 'and'}
                                            ],
                                            reverse: true,
                                        },
                                        detail: {
                                            name: '선택된 수단',
                                            headers: [
                                                {title: '종류', field: 'type', type: 'string'},
                                            ],
                                        }
                                    },

                                    {title: '상품 목록', field: 'product_counts', type: 'list', headers: [
                                            {title: '상품', field: 'product_id', type: 'item',
                                                table: {
                                                    name: '상품 목록',
                                                    partition: 'new_product',
                                                    headers: [
                                                        {title: '이름', field: 'name', type: 'string'},
                                                        {title: '제목', field: 'title', type: 'string'},
                                                    ],
                                                    searchFields: [],
                                                    query: [
                                                        {condition: 'eq', field: 'visible', value: true, option: 'and'},
                                                        {condition: 'eq', field: 'visible_store', value: true, option: 'or'},
                                                    ],
                                                    reverse: true,
                                                },
                                                detail: {
                                                    name: '상품',
                                                    headers: [
                                                        {title: '이름', field: 'name', type: 'string'},
                                                        {title: '제목', field: 'title', type: 'string'},
                                                    ],
                                                }
                                            },
                                            {title: '개수', field: 'count', type: 'number'}
                                        ]},

                                ]}
                                onSave={(item) => {
                                    if (this.getItemId()){
                                        API.database.updateItem(item.id, item).then((response)=> {
                                            if (response.success){
                                                alert("성공");
                                            }else if (response.error){
                                                alert(response.error.message);
                                            }
                                        })
                                    }else{
                                        if (!item.payment_method_id){
                                            alert("결제 수단을 입력해주세요");
                                            return;
                                        }
                                        if (!item.weeks){
                                            alert("주기를 입력해주세요");
                                            return;
                                        }
                                        item.enabled = true;

                                        API.database.createItem('subscription', item).then(response => {
                                            if (response.item_id){
                                                alert("성공! 목록으로 되돌아가면 항목이 생성되어있습니다.");
                                            }else if (response.error){
                                                alert(response.error.message);
                                            }
                                        });
                                    }

                                }}
                            />
                        </Col>
                    </Row>

                </Container>

            </>
        );
    }

}

export default SubscriptionDetail;
