import * as React from "react";
import Button from "react-bootstrap/Button";
import API from "../server/service/api/API";
import Modal from "react-bootstrap/Modal";
import moment from 'moment';
import {CSVDownload, CSVLink} from "react-csv";

export default class ExportCSVButton extends React.Component{

    state = {
        downloader: null,
        showModal: false,
        downloadLinks: [],
        chunk: 0,
    };

    componentDidMount() {

    }

    getPartition = () => {
        return this.props.partition;
    };

    flattenItem = (item) => {
        let flatItem = {};
        for (const key of Object.keys(item)){
            if (item[key] instanceof Object){
                const d_flatItem = this.flattenItem(item[key]);
                for (const d_key of Object.keys(d_flatItem)){
                    flatItem[key + '.' + d_key] = d_flatItem[d_key];
                }
            }
            else{
                flatItem[key] = item[key];
            }
        }
        return flatItem;
    };

    prepareItems = (items) => {
        // 아이템 전처리
        return items.map(item => {
            for (const key of Object.keys(item)){
                if (key.endsWith("_date") && !isNaN(item[key])){
                    let timestamp = moment.unix(item[key]);
                    item[key] = timestamp.format("YYYY-MM-DD HH:mm:ss");
                }
                if (['password_hash', 'salt'].includes(key)){
                    item[key] = '';
                }
            }
            return this.flattenItem(item)
        });
    };

    doQuery = async (partition, query) => {
        query = JSON.parse(JSON.stringify(query));
        if (partition === 'user'){
            query.push({
                condition: 'neq',
                field: 'role',
                value: 'Admin session for running function',
                option: 'and'
            });
        }
        console.log(query);
        let start_key = null;
        let count = 0;
        const chunkSize = 10000;
        let itemsChunk = [];
        do {
            const response = await API.database.queryItems(partition, query, 1000, true, start_key);
            start_key = response.end_key;
            let items = response.items;
            items = this.prepareItems(items);
            itemsChunk = itemsChunk.concat(items);
            this.setState({
                chunk: itemsChunk.length
            });

            if (itemsChunk.length >= chunkSize || start_key === null || start_key === undefined || start_key === 'undefined'){
                const downloader = <CSVDownload filename={ partition + "_" + count + ".csv"} data={itemsChunk} target="_blank" />;
                // const downloader = <p><CSVLink filename={ partition + "_" + count + ".csv"} data={items} target="_blank">
                //     {partition + "_" + count + ".csv"}
                // </CSVLink></p>;
                this.state.downloadLinks.push(downloader);
                this.setState({
                    // downloader: downloader,
                    downloadLinks: this.state.downloadLinks
                });
                count++;
                itemsChunk = [];
            }

        } while (start_key);
    };

    getFileOption = () => {
        return "";
    };

    downloadExcelFile = (query) => {
        let partition = this.getPartition();

        this.setState({
            showModal: true,
            downloader: null,
        });
        this.doQuery(partition, query);
    };

    render() {
        let query = [];
        if (this.props.query){
            query = query.concat(this.props.query);
        }
        return (<>
                <Modal size={'xl'} show={this.state.showModal} onHide={()=> this.setState({showModal:false})}>
                    <Modal.Header closeButton/>
                    <p>{this.state.chunk} 개 로드 중...</p>
                    <Modal.Body>
                        {this.state.downloadLinks}
                    </Modal.Body>
                </Modal>
                <Button onClick={()=> this.downloadExcelFile(query)} className={'btn btn-success ml-1'}>
                    CSV 출력
                </Button>

            </>
        );
    }

}

