import React, {Component} from "react";
import AutoTable from "../../../components/autoTable";
import Sidebar from "../sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AutoDetail from "../../../components/autoDetail";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Users from "./users";
import API from "../../../server/service/api/API";


class UserOrderDetail extends Component{

    static path = '/user/detail/order/:item_id';

    state = {

    };

    constructor(props){
        super(props);
    };

    getItemId = () => {
        return this.props.match.params.item_id;
    };

    componentDidMount() {

    }

    render() {
        return (
            <>
                <Sidebar history={this.props.history}/>

                <Breadcrumb>
                    <Breadcrumb.Item active>회원 관리</Breadcrumb.Item>
                    <Breadcrumb.Item href={Users.path}>회원 목록</Breadcrumb.Item>
                    <Breadcrumb.Item href={window.location.href}>결제 정보</Breadcrumb.Item>
                </Breadcrumb>

                <Container fluid className={'mt-4 mb-4'}>

                    <Row>
                        <Col className={'col-12 mt-4'}>
                            <AutoDetail
                                name={'결제 정보'}
                                itemId={this.getItemId()}
                                headers={[
                                    {title: '생성일', field: 'creation_date', type: 'date', readOnly: true},
                                    {title: '상태', field: 'status', type: 'string'},
                                    {title: '이름', field: 'name', type: 'string'},
                                    {title: '금액', field: 'price', type: 'number', readOnly: true},
                                    {title: '배송정보', field: 'shipping_info', type: 'dict', headers: [
                                            {title: '주문자', field: 'buyer_name', type: 'string'},
                                            {title: '핸드폰 번호', field: 'buyer_tel', type: 'string'},
                                            {title: '배송지', field: 'buyer_addr_road', type: 'string'},
                                            {title: '배송지 상세', field: 'buyer_addr_detail', type: 'string'},
                                            {title: '우편번호', field: 'buyer_postcode', type: 'string'},
                                        ]},

                                    {title: '상품 목록', field: 'product_counts', type: 'list', headers: [
                                            {title: '상품', field: 'product_id', type: 'item',
                                                table: {
                                                    name: '상품 목록',
                                                    partition: 'new_product',
                                                    headers: [
                                                        {title: '이름', field: 'name', type: 'string'},
                                                        {title: '제목', field: 'title', type: 'string'},
                                                    ],
                                                    searchFields: [],
                                                    query: [],
                                                    reverse: true,
                                                },
                                                detail: {
                                                    name: '상품',
                                                    headers: [
                                                        {title: '이름', field: 'name', type: 'string'},
                                                        {title: '제목', field: 'title', type: 'string'},
                                                    ],
                                                }
                                            },
                                            {title: '개수', field: 'count', type: 'number'}
                                        ]},

                                ]}
                                onSave={(item) => {
                                    API.database.updateItem(item.id, item).then((response)=> {
                                        if (response.success){
                                            alert("성공");
                                        }else if (response.error){
                                            alert(response.error.message);
                                        }
                                    })
                                }}
                            />
                        </Col>
                    </Row>

                </Container>

            </>
        );
    }

}

export default UserOrderDetail;
