import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Login from "../pages/login";
import Dashboard from "../pages/dashboard/dashboard";
import Users from "../pages/dashboard/member/users";
import UserDetail from "../pages/dashboard/member/userDetail";
import WithdrawalRecords from "../pages/dashboard/member/withdrawalRecords";
import WithdrawalRecordDetail from "../pages/dashboard/member/withdrawalRecordDetail";
import Products from "../pages/dashboard/sales/Products";
import ProductDetail from "../pages/dashboard/sales/ProductDetail";
import UserOrderDetail from "../pages/dashboard/member/userOrderDetail";
import SubscriptionLevels from "../pages/dashboard/sales/SubscriptionLevels";
import SubscriptionLevelDetail from "../pages/dashboard/sales/SubscriptionLevelDetail";
import Orders from "../pages/dashboard/sales/Orders";
import OrderDetail from "../pages/dashboard/sales/OrderDetail";
import Subscriptions from "../pages/dashboard/sales/Subscriptions";
import SubscriptionDetail from "../pages/dashboard/sales/SubscriptionDetail";
import FreePriceDetail from "../pages/dashboard/sales/FreePriceDetail";
import Mileages from "../pages/dashboard/member/mileages";
import Inventories from "../pages/dashboard/sales/Inventories";
import InventoryDetail from "../pages/dashboard/sales/InventoryDetail";
import Reviews from "../pages/dashboard/sales/Reviews";
import ReviewDetail from "../pages/dashboard/sales/ReviewDetail";


class App extends Component {
    render() {
        return (
            <div>

                <Route exact path={'/'} component={Login}/>
                <Route exact path={Dashboard.path} component={Dashboard}/>

                <Route exact path={Login.path} component={Login}/>

                <Route exact path={Users.path} component={Users}/>
                <Route exact path={Mileages.path} component={Mileages}/>
                <Route exact path={UserDetail.path} component={UserDetail}/>
                <Route exact path={WithdrawalRecords.path} component={WithdrawalRecords}/>
                <Route exact path={WithdrawalRecordDetail.path} component={WithdrawalRecordDetail}/>


                <Route exact path={Products.path} component={Products}/>
                <Route exact path={ProductDetail.path} component={ProductDetail}/>
                <Route exact path={ProductDetail.creation_path} component={ProductDetail}/>

                <Route exact path={UserOrderDetail.path} component={UserOrderDetail}/>
                <Route exact path={UserOrderDetail.path.split(':')[0]} component={UserOrderDetail}/>

                    <Route exact path={SubscriptionLevels.path} component={SubscriptionLevels}/>
                    <Route exact path={SubscriptionLevelDetail.path} component={SubscriptionLevelDetail}/>
                    <Route exact path={SubscriptionLevelDetail.creation_path} component={SubscriptionLevelDetail}/>

                <Route exact path={Orders.path} component={Orders}/>
                <Route exact path={OrderDetail.path} component={OrderDetail}/>
                <Route exact path={OrderDetail.path.split(':')[0]} component={OrderDetail}/>

                <Route exact path={Subscriptions.path} component={Subscriptions}/>
                <Route exact path={SubscriptionDetail.path} component={SubscriptionDetail}/>
                <Route exact path={SubscriptionDetail.path.split(':')[0]} component={SubscriptionDetail}/>


                <Route exact path={FreePriceDetail.path} component={FreePriceDetail}/>


                <Route exact path={Inventories.path} component={Inventories}/>
                <Route exact path={InventoryDetail.path} component={InventoryDetail}/>
                <Route exact path={InventoryDetail.creation_path} component={InventoryDetail}/>


                <Route exact path={Reviews.path} component={Reviews}/>
                <Route exact path={ReviewDetail.path} component={ReviewDetail}/>
                <Route exact path={ReviewDetail.creation_path} component={ReviewDetail}/>



            </div>
        );
    }
}

export default App;
