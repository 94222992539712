import React, {Component} from "react";
import Sidebar from "../sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AutoDetail from "../../../components/autoDetail";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import API from "../../../server/service/api/API";
import Orders from "./Orders";


class OrderDetail extends Component{

    static path = '/order/:item_id';

    state = {

    };

    constructor(props){
        super(props);
    };

    getItemId = () => {
        return this.props.match.params.item_id;
    };

    componentDidMount() {

    }

    getOrderName = async (item) => {
        let name = "";
        let product_counts = item.product_counts;
        for (let [idx, product_count] of product_counts.entries()){
            let product_id = product_count.product_id;
            let count = product_count.count;
            let product = await API.database.getItem(product_id);
            product = product.item;
            let productTitle = product.title;
            name += productTitle + " " + count + "개";
            if (idx !== product_counts.length - 1){
                name += ", ";
            }
        }
        return name;
    };

    getOrderPrice = async (item) => {
        let price = 2500;
        let product_counts = item.product_counts;
        if (product_counts.length >= 5){
            price = 0;
        }
        for (let [idx, product_count] of product_counts.entries()){
            let product_id = product_count.product_id;
            let count = product_count.count;
            let product = await API.database.getItem(product_id);
            product = product.item;
            if (item.for_unit){
                price += product.unit_price * count;
            }else{
                price += product.subscription_price * count;
            }
        }
        return price;
    };

    render() {
        return (
            <>
                <Sidebar history={this.props.history}/>

                <Breadcrumb>
                    <Breadcrumb.Item active>회원 관리</Breadcrumb.Item>
                    <Breadcrumb.Item href={Orders.path}>주문 목록</Breadcrumb.Item>
                    <Breadcrumb.Item href={window.location.href}>결제 정보</Breadcrumb.Item>
                </Breadcrumb>

                <Container fluid className={'mt-4 mb-4'}>

                    <Row>
                        <Col className={'col-12 mt-4'}>
                            <AutoDetail
                                name={'결제 정보'}
                                itemId={this.getItemId()}
                                headers={[
                                    {title: '생성일', field: 'creation_date', type: 'date', readOnly: true},
                                    {title: '체험판', field: 'is_trial', type: 'boolean', readOnly: true},
                                    {title: '상태', field: 'status', type: 'string', options: ['standby', 'shipping', 'complete']},
                                    {title: '이름', field: 'name', type: 'string', readOnly: true},
                                    {title: '금액', field: 'price', type: 'number', readOnly: true},

                                    {title: '운송장번호', field: 'shipping_number', type: 'string'},
                                    {title: '배송정보', field: 'shipping_info', type: 'dict', headers: [
                                            {title: '주문자', field: 'buyer_name', type: 'string'},
                                            {title: '핸드폰 번호', field: 'buyer_tel', type: 'string'},
                                            {title: '주소', field: 'buyer_postcode', type: 'postcode',
                                                addressField: 'buyer_addr_road'},
                                            {title: '배송지 상세', field: 'buyer_addr_detail', type: 'string'},
                                        ]},

                                    {title: '상품 목록', field: 'product_counts', type: 'list', headers: [
                                            {title: '상품', field: 'product_id', type: 'item',

                                                detail: {
                                                    name: '상품',
                                                    headers: [
                                                        {title: '이름', field: 'name', type: 'string'},
                                                        {title: '제목', field: 'title', type: 'string'},
                                                    ],
                                                },
                                                table: {
                                                    partition: 'new_product',
                                                    headers: [
                                                        {title: '이름', field: 'title', type: 'string'}
                                                    ],
                                                    query: [
                                                        {condition: 'eq', field: 'visible', value: true, option: 'and'},
                                                        {condition: 'eq', field: 'visible_store', value: true, option: 'or'}
                                                        ]
                                                }
                                            },
                                            {title: '개수', field: 'count', type: 'number'}
                                        ]},

                                ]}
                                onSave={async (item) => {
                                    item.name = await this.getOrderName(item);
                                    item.price = await this.getOrderPrice(item);
                                    if (item.id){
                                        API.database.updateItem(item.id, item).then((response)=> {
                                            if (response.success){
                                                alert("성공");
                                            }else if (response.error){
                                                alert(response.error.message);
                                            }
                                        })
                                    }else{
                                        API.database.createItem('new_order', item).then((response)=> {
                                            if (response.item_id){
                                                alert("성공");
                                            }else if (response.error){
                                                alert(response.error.message);
                                            }
                                        })
                                    }

                                }}
                            />
                        </Col>
                    </Row>

                </Container>

            </>
        );
    }

}

export default OrderDetail;
