import React, {Component} from "react";
import Sidebar from "../sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import AutoDetail from "../../../components/autoDetail";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import API from "../../../server/service/api/API";
import Products from "./Products";
import Col from "react-bootstrap/Col";
import SubscriptionLevels from "./SubscriptionLevels";
import Inventories from "./Inventories";


export default class InventoryDetail extends Component{

    static path = '/inventory/detail/:item_id';
    static creation_path = InventoryDetail.path.split(':')[0];

    state = {

    };

    constructor(props){
        super(props);
    };

    getProductId = () => {
        return this.props.match.params.item_id;
    };

    componentDidMount() {

    }

    render() {
        return (
            <>
                <Sidebar history={this.props.history}/>

                <Breadcrumb>
                    <Breadcrumb.Item active>판매 관리</Breadcrumb.Item>
                    <Breadcrumb.Item href={Inventories.path}>재고 목록</Breadcrumb.Item>
                    <Breadcrumb.Item href={window.location.href}>상세</Breadcrumb.Item>
                </Breadcrumb>

                <Container fluid className={'mt-4 mb-4'}>

                    <Row>
                        <Col>
                            <AutoDetail
                                name={'정보'}
                                itemId={this.getProductId()}
                                headers={[
                                    {title: '이름', field: 'name', type: 'string'},
                                    {title: '잔여량', field: 'remain_count', type: 'number'},
                                    {title: '최소잔여량', field: 'min_count', type: 'number'},
                                    {title: '증가단위개수', field: 'increase_count', type: 'number'},
                                    {title: '구매처 URL', field: 'url', type: 'string'},
                                    {title: '감소 형식', field: 'type', type: 'string', options: ['order', 'product']},
                                    {title: '(order) 상품 개수 최소', field: 'product_count_min', type: 'number'},
                                    {title: '(order) 상품 개수 최대', field: 'product_count_max', type: 'number'},
                                    {title: '생성일', field: 'creation_date', type: 'date'},
                                ]}
                                onSave={(item) => {
                                    if (this.getProductId()){
                                        API.database.updateItem(item.id, item).then(data => {
                                            if (data.success) {
                                                alert("성공!");
                                            }
                                        });
                                    }else{
                                        API.database.createItem('inventory', item).then(data => {
                                            if (data.item_id) {
                                                alert("성공!");
                                            }
                                        });
                                    }

                                }}
                            />
                        </Col>
                    </Row>

                </Container>

            </>
        );
    }

}
