import Form from "react-bootstrap/Form";
import React from "react";
import Postcode from "./Postcode";


class PostcodeField extends React.Component{

    state = {
        openAddressModal: false,
        postcode: this.props.postcode,
        address: this.props.address
    };

    render() {
        return <>

            <Form.Control
                className={'mb-3'}
                type="text"
                onChange={this.handleSetItemString}
                placeholder={"주소"}
                value={this.state.address}
                onFocus={()=>{
                    this.setState({
                        openAddressModal: true,
                    })
                }}
            />


            <Form.Control
                type="text"
                onChange={this.handleSetItemString}
                placeholder={"우편번호"}
                value={this.state.postcode}
                onFocus={()=>{
                    this.setState({
                        openAddressModal: true,
                    })
                }}
                />

            <Postcode show={this.state.openAddressModal}
                      onClose={()=>{
                        this.setState({
                            openAddressModal: false,
                        })}}
                        onComplete={(postcode, address)=>{
                            this.setState({
                                postcode, address,
                                openAddressModal: false
                            });
                            this.props.onChange(postcode, address);
                        }}
                      />
            </>
    }

}

export default PostcodeField;
