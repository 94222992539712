
import React, {Component} from "react";
import AutoTable from "../../../components/autoTable";
import Sidebar from "../sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import API from "../../../server/service/api/API";
import Config from "../../../server/Config";
import SendAlimtalk from "../../../components/SendAlimtalk";


class Users extends Component{

    static path = '/users';

    state = {
        showAlimtalk: false,
        phone_numbers: [],
        templates: [],
    };
    render() {
        return (
            <>
                <Sidebar history={this.props.history}/>

                <Breadcrumb>
                    <Breadcrumb.Item active>회원 관리</Breadcrumb.Item>
                    <Breadcrumb.Item href={window.location.href}>회원 목록</Breadcrumb.Item>
                </Breadcrumb>

                <SendAlimtalk show={this.state.showAlimtalk}
                              phone_numbers={this.state.phone_numbers}
                              templates={this.state.templates}
                              onClose={()=>{
                                  this.setState({
                                      showAlimtalk: false
                                  })
                              }}/>

                <Container fluid className={'mt-4 mb-4'}>

                    <Row>
                        <Col>
                            <AutoTable
                                name={'회원 목록'}
                                partition={'user'}
                                searchFields={['name', 'email_addr']}
                                searchAsIndex={true}
                                query={[]}
                                actions={[
                                    {
                                        name: '테스트',
                                        itemHandler: (users) => {
                                            let phone_numbers = users.map(user => {
                                                return user.phone;
                                            });
                                            alert(phone_numbers);

                                        }
                                    }, {
                                        name: '선택 알림톡 전송', itemHandler: async (users) => {
                                            let phone_numbers = users.map(user => {
                                                return user.phone;
                                            });

                                            const getTemplates = async () => {
                                                let templates = await API.logic.runFunction(Config.functionName, {
                                                    cmd: 'notification.get_templates'
                                                });
                                                return templates;
                                            };
                                            const templates = await getTemplates();

                                            this.setState({
                                                showAlimtalk: true,
                                                templates, phone_numbers
                                            })

                                        }
                                    }
                                ]}
                                limit={100}
                                headers={[
                                    {title: '이름', field: 'name', type: 'string'},
                                    {title: '이메일', field: 'email', type: 'string'},
                                    {title: '로그인방식', field: 'login_method', type: 'string'},
                                    {title: '전화번호', field: 'phone', type: 'string'},
                                    {title: '성별', field: 'gender', type: 'string'},
                                    {title: '마일리지', field: 'mileage', type: 'number'},
                                    {title: '가입일', field: 'creation_date', type: 'date'},
                                ]}
                                reverse={true}
                                onShowDetail={(item) => {
                                    this.props.history.push('/user/detail/' + item.id);
                                }}
                                onDeleteItem={(item) => {
                                    if (window.confirm('정말 삭제하시겠습니까?')){
                                        API.auth.deleteUser(item.id).then((data)=>{
                                            alert(JSON.stringify(data));
                                            if (data.success){
                                                window.location.reload();
                                            }
                                        });
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                </Container>

            </>
        );
    }

}

export default Users;
