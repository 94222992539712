import React, {Component} from "react";
import AutoDetail from "./autoDetail";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";


class AutoList extends Component{

    state = {
        items: this.props.items,
        list: [],
    };

    handleItemChanged = (index, item) => {
        this.state.items[index] = item;
        this.onChange();
    };

    handleItemDeleted = (index) => {
        this.state.items.splice(index, 1);
        this.loadList();
        this.onChange();
    };

    handleItemAdded = () => {
        if (!this.state.items){
            this.state.items = [];
        }
        let items = this.state.items;
        let item = {};
        this.props.headers.forEach((header)=> {
            item[header.field] = null;
        });
        items.push(item);
        this.setState({items});
        this.loadList();
        this.onChange();
    };

    loadList = () => {
        let items = this.state.items;
        if (!items){
            return;
        }
        let headers = this.props.headers;
        let list = Array(items.length);
        items.forEach((item, index)=> {
            let row = <div><AutoDetail
                                key={JSON.stringify(item) + index}
                                item={item}
                                headers={headers}
                                onChange={(item) => this.handleItemChanged(index, item)}
                                onDelete={(item) => this.handleItemDeleted(index)}
                            />
                <Button variant={'primary'} className={'mt-1'} onClick={()=>{
                    this.moveIndex(index, -1);
                }}>위로</Button>

                <Button variant={'info'} className={'ml-1 mt-1'} onClick={()=>{
                    this.moveIndex(index, 1);
                }}>아래로</Button>

            </div>;
            list[index] = row;
        });
        this.setState({list})
    };

    moveIndex = (index, offset) => {
        let newItems = this.state.items;
        if (!newItems){
            return;
        }
        let indexToMove = index + offset;
        if (indexToMove < 0){
            indexToMove = newItems.length - 1;
        }
        indexToMove = indexToMove % newItems.length;
        const temp = newItems[indexToMove];
        newItems[indexToMove] = newItems[index];
        newItems[index] = temp;
        this.setState({
            items: newItems
        });
        this.loadList();
    };

    onChange = () => {
        if (this.props.onChange){
            this.props.onChange(this.state.items);
        }
    };

    componentDidMount() {
        this.loadList();
    }

    render() {
        return (

            <div className="card mt-2">
                <div className="card-body">
                    {this.state.list}
                    <Row>
                        <Col className={'mt-2'}>
                            <Button onClick={this.handleItemAdded} className={'btn btn-block'}>추가</Button>
                        </Col>
                    </Row>
                </div>
            </div>

        );
    }

}

export default AutoList;
