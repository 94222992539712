import React, {Component} from "react";
import API from '../server/service/api/API';
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Spinner from "react-bootstrap/Spinner";
import FormText from "react-bootstrap/FormText";
import ExportExcelButton from "./ExportExcelButton";
import {FormCheck} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import CardBody from "reactstrap/es/CardBody";
import Card from "react-bootstrap/Card";
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
import EllipsisText from "react-ellipsis-text";
import ExportCSVButton from "./ExportCSVButton";


const AsyncFunction = (async () => {}).constructor;
const GeneratorFunction = (function* () {}).constructor;

const isAsyncFunction = value => value instanceof AsyncFunction;
const isGeneratorFunction = value => value instanceof GeneratorFunction;

class AutoTable extends Component {

    // <AutoTable
    //     name='서비스 목록'
    //     partition='service'
    //     headers={[
    //         {title: '이름', field: 'name', type: 'string'},
    //         {title: '생성일', field: 'creation_date', type: 'date'},
    //     ]}
    //     searchFields={['name', 'nickname']}
    //     query={[{field: 'name', value: '김창환', option: 'and', condition: 'eq'}]}
    //     reverse={true} />

    state = {
        itemIds: [],
        items: [],
        rows: [],
        query: [],
        checkedIndexes: {},
        searchFilters: [],
        busy: false
    };

    startKey = null;
    subQuery = [];
    fields = new Set();
    searchField = null;
    searchCondition = 'eq';

    reloadRows = () => {
        this.clearRows();
        this.appendRows();
    };


    getBackground = () => {
        return this.props.background;
    };

    checkAll = () => {
        let checkedIndexes = this.state.checkedIndexes;
        this.state.rows.forEach((row, index) => {
            checkedIndexes[index] = true;
        });
        this.setState({
            checkedIndexes: checkedIndexes
        });
    };

    clearRows = () => {
        this.startKey = null;
        this.setState({rows: []});
        this.setState({itemIds: []})
        this.setState({items: []})
    };

    addFields = (item) => {
        Object.keys(item).forEach(key => {
           this.fields.add(key);
        });
    };

    appendRows = () => {
        if (this.state.busy){
            return;
        }
        this.setState({
            busy: true
        });
        let partition = this.props.partition;
        let query = this.state.query;
        let startKey = this.startKey;
        let reverse = !!this.props.reverse;
        let join = this.props.join;
        let limit = this.props.limit;
        let sortKey = this.props.sortKey;
        //let sortKey = this.props.sortKey;

        if (!limit){
            limit = 100
        }
        if (partition === 'user' || this.props.useQueryFront){
            query = this.subQuery.concat(query);
        }else{
            query = query.concat(this.subQuery);
        }

        API.database.queryItems(partition, query, limit, reverse, startKey, 'creation_date', join).then((data) => {
            // alert(JSON.stringify(data))
            let items = data.items;
            if (!items){
                items = [];
            }
            //alert('items:' + items.length);
            if (sortKey){
                items.sort((a, b)=> {
                    return b[sortKey] - a[sortKey];
                });
            }
            this.startKey = data.end_key;

            // if (partition === 'user_transaction'){
            //     alert(items.length + ', ' + JSON.stringify(data.end_key));
            // }

            let rows = Array(items.length);
            let itemIds = items.map(item => {
                return item.id;
            });
            let _items = items.map(item => {
                return item;
            });
            let count = 0;
            items.forEach((item, index)=> {
                this.addFields(item);
                let prevLen = this.state.rows.length;
                if (!prevLen){
                    prevLen = 0;
                }
                this.getRowPromise(item, index + prevLen).then((row)=> {
                    rows[index] = row;
                    count += 1;
                    if (count === items.length){
                        let oldRows = this.state.rows;
                        let oldItemIds = this.state.itemIds;
                        let oldItems = this.state.items;
                        rows = oldRows.concat(rows);
                        console.log(rows);
                        itemIds = oldItemIds.concat(itemIds);
                        _items = oldItems.concat(_items);
                        this.setState({rows: rows});
                        this.setState({itemIds});
                        this.setState({items: _items});
                        this.setState({
                            busy: false
                        })
                    }
                })
            });
            if (items.length === 0){
                this.setState({
                    busy: false
                })
            }
        });
    };

    getValue = (item, fieldName) => {
        if (fieldName.includes('.')){
            let value = item;
            let fields = fieldName.split('.');
            fields.forEach((field)=> {
                if (value){
                    value = value[field];
                }
            });
            return value;
        }
        return item[fieldName];
    };

    getValuePromise = (item, header) => {
        return new Promise(resolve => {
            let fieldName = header.field;
            let fieldType = header.type;
            let transformer = header.transformer;
            let raw = this.getValue(item, fieldName);
            if (fieldType === 'string'){
                let text = <CopyToClipboard text={raw}><FormText
                    style={{
                        maxWidth: 120
                    }}
                    onClick={() => {
                    alert("클립보드에 복사되었습니다.");
                }}>{raw}</FormText></CopyToClipboard>;
                resolve(text);
            }else if (fieldType === 'number'){
                let text = Math.round(raw * 1000) / 1000;
                resolve(text);
            }else if (fieldType === 'date'){
                let dateString = new Date(raw * 1000).toLocaleDateString("ko-KR");
                let timeString = new Date(raw * 1000).toLocaleTimeString("ko-KR");
                let dateResult = dateString + ' ' + timeString;
                resolve(dateResult);
            }else if (fieldType === 'image'){
                let width = header.width;
                let height = header.height;
                if (!width){
                    width = 64
                }
                if (!height){
                    height = 64
                }
                if (!raw){
                    resolve(<img/>);
                    return
                }
                API.storage.downloadB64(raw).then((data) => {
                    resolve(<img width={width} height={height} src={`data:image/jpeg;base64,${data.file_b64}`} />);
                });
            }else if (fieldType === 'boolean') {
                if (raw) {
                    resolve(<Badge variant="success">True</Badge>);
                } else {
                    resolve(<Badge variant="warning">False</Badge>);
                }
            }else if (fieldType === 'dict' && raw) {
                let dict = [];
                Object.keys(raw).forEach(key => {
                    let value = raw[key];
                    let row = <Form.Row>
                        <Form.Label column="sm">
                            <b>{key}</b>
                        </Form.Label>
                        <Form.Label column="sm">
                            {value}
                        </Form.Label>
                    </Form.Row>;
                    dict.push(row);
                });
                resolve(dict);

            }else if (fieldType === 'strings'){
                let badges = [];
                raw.forEach(word => {
                    if (word && word.length > 0){
                        let badge = <CopyToClipboard text={word}>
                            <Badge
                                onClick={() => {
                                    alert("클립보드에 복사되었습니다.");
                                }}
                                variant={'primary ml-1'}>
                                <b>{word}</b>
                            </Badge>
                        </CopyToClipboard>;
                        badges.push(badge);
                    }
                });
                resolve(badges);
            }else if (fieldType === 'count'){
                let count = 0;
                if (Array.isArray(raw)){
                    count = raw.length;
                }
                let countText = <FormText>{count}</FormText>;
                resolve(countText);
            }else if (fieldType === 'transform'){
                if (isAsyncFunction(transformer)){
                    (async ()=>{
                        let value = await transformer(raw);
                        console.log(value);
                        resolve(value);
                    })();
                }else{
                    let value = transformer(raw);
                    resolve(value);
                }
            }else if (fieldType === 'custom'){
                if (isAsyncFunction(transformer)){
                    (async ()=>{
                        let value = await transformer(item);
                        console.log(value);
                        resolve(value);
                    })();
                }else{
                    let value = transformer(item);
                    resolve(value);
                }
            }else{
                resolve(<div>{item[fieldName]}</div>);
            }
        })
    };

    onShowDetail = (item) => {
        if (this.props.onShowDetail){
            this.props.onShowDetail(item);
        }
    };

    onDeleteItem = (item) => {
        if (this.props.onDeleteItem){
            this.props.onDeleteItem(item);
        }
    };

    onSelectItem = (item) => {
        if (this.props.onSelectItem){
            this.props.onSelectItem(item);
        }
    };

    onClickCreate = () => {
        if (this.props.onClickCreate){
            this.props.onClickCreate();
        }
    };

    onSearch = () => {
        // if (!this.props.searchFields){
        //     alert("AutoTable 객체의 searchFields 속성이 비어있습니다. 관리자에게 문의하세요.");
        //     return;
        // }
        // let keyword = this.state.keyword;
        // this.subQuery = [];
        // this.props.searchFields.forEach((value, idx) => {
        //     let field = value;
        //     let option = 'and';
        //     if (idx > 0){
        //         option = 'or'
        //     }
        //     if (this.props.searchAsIndex === true){
        //         this.subQuery.push({field: field, value: keyword, condition: 'in', option: option});
        //     }else{
        //         this.subQuery.push({field: field, value: keyword, condition: 'in', option: option});
        //     }
        // });
        // if (!keyword){
        //     this.subQuery = [];
        // }
        let searchFilters = this.state.searchFilters;
        if (searchFilters){
            this.subQuery = searchFilters;
        }else{
            this.subQuery = [];
        }

        this.clearRows();
        this.appendRows();
    };

    handleKeyPress = (e) => {
        if (e.key === "Enter") {
            this.onSearch();
        }
    };

    handleSetText = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    setSpinner = (visible) => {
        this.setState({spinnerVisible: visible});
        if (visible){
            let spinner = <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            />;
            this.setState({spinner});
        }else{
            this.setState({spinner: null});
        }
    };

    getButtonGroups = (item) => {
        let buttons = [];
        if (this.props.buttonActions){
            this.props.buttonActions.forEach(buttonAction=>{
                buttons.push(<Button onClick={() => buttonAction.handler(item)} variant={buttonAction.variant} className={'btn-sm ml-1 mt-1'}>{buttonAction.name}</Button>)
            });
        }
        if (this.props.onShowDetail){
            buttons.push(<Button onClick={() => this.onShowDetail(item)} className={'btn-sm ml-1 mt-1'}>선택</Button>)
        }
        if (this.props.onDeleteItem){
            buttons.push(<Button disabled={this.state.spinnerVisible} onClick={() => {
                this.onDeleteItem(item);
                this.setSpinner(true);
            }} className={'btn-sm btn-danger ml-1 mt-1'}>
                {this.state.spinner}
                삭제
            </Button>)
        }
        if (this.props.onSelectItem){
            buttons.push(<Button onClick={() => this.onSelectItem(item)} className={'btn-sm ml-1 mt-1'}>선택</Button>);
        }
        return buttons;
    };

    getRowPromise = (item, row_index) => {
        return new Promise((resolve) => {
            let row = Array(this.props.headers.length);
            let count = 0;
            this.props.headers.forEach((header, index)=>{
                this.getValuePromise(item, header).then((value => {
                    row[index] = <td>{value}</td>;
                    count += 1;
                    if (count === this.props.headers.length){
                        resolve(<>
                            <td>{row_index + 1}</td>
                            {row}
                            <td>
                                {this.getButtonGroups(item)}
                            </td>
                        </>);
                    }
                }));
            });
        })
    };

    getTopButtons = () => {
        let buttons = [];
        let query = this.state.searchFilters;
        if (this.props.partition === 'user' || this.props.useQueryFront){
            query = this.subQuery.concat(query);
        }else{
            query = query.concat(this.subQuery);
        }
        // buttons.push(<ExportCSVButton partition={this.props.partition} query={query}/>);
        buttons.push(<ExportExcelButton transformItem={this.props.transformItem} partition={this.props.partition} query={this.props.query}/>);
        if (this.props.onClickCreate){
            buttons.push(<Button onClick={()=>this.onClickCreate()} className={'ml-2'} variant={'success'}>항목 생성</Button>)
        }
        return buttons;
    };

    getActions = () => {
        if (this.props.actions){
            return this.props.actions;
        }
        return [];
    };

    getSelectedItemIds = () => {
        let itemIds = Object.keys(this.state.checkedIndexes).filter(index => {
            return this.state.checkedIndexes[index];
        }).map(index => {
            return this.state.itemIds[index];
        });
        return itemIds;
    };

    getSelectedItems = () => {
        let items = Object.keys(this.state.checkedIndexes).filter(index => {
            return this.state.checkedIndexes[index];
        }).map(index => {
            return this.state.items[index];
        });
        return items;
    };

    fetchActionHandlerWithItemIds = (handler) => {
        handler(this.getSelectedItemIds(), ()=>{
            this.clearRows();
            this.appendRows();
        });
    };

    fetchActionHandlerWithItems = (handler) => {
        handler(this.getSelectedItems(), ()=>{
            this.clearRows();
            this.appendRows();
        });
    };

    headers = () => {
        let hs = this.props.headers.map((header) => {
            return (<th scope={'col'}><text style={{
                fontSize: 13
            }}>{header.title}</text><br/><text style={{
                fontSize: 8,
                fontWeight: 300,
            }}><EllipsisText text={header.field} length={"17"} /></text></th>)
        });
        let actions = this.getActions().map(action => {
            return <Dropdown.Item onClick={()=>{
                if (action.handler){
                    this.fetchActionHandlerWithItemIds(action.handler);
                }
                if (action.itemHandler){
                    this.fetchActionHandlerWithItems(action.itemHandler);
                }
            }}>{action.name}</Dropdown.Item>
        });

        let dropdown = <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item on onClick={this.checkAll}>전체 선택</Dropdown.Item>
                <Dropdown.Divider/>
                {actions}
            </Dropdown.Menu>
        </Dropdown>;

        if (actions.length === 0){
            dropdown = <></>;
        }

        return (<><th>
            {dropdown}
        </th><th>#</th>{hs}</>);
    };

    prepareSearchPlaceholder = () => {
        if (this.props.searchFields && this.props.searchFields.length > 0){
            // let ph = this.props.searchFields.join(', ');
            let ph = "키워드";
            this.setState({seachPlaceholder: ph});
        }
    };

    componentDidMount() {
        if (this.props.searchFilters){
            this.setState({
                searchFilters: this.props.searchFilters
            })
        }
        this.appendRows();
        this.prepareSearchPlaceholder();
        if (this.props.getReloader){
            this.props.getReloader(this.reloadRows);
        }
    }

    constructor(props) {
        super(props);
        if (this.props.query) {
            this.state.query = [];
            this.state.query = this.state.query.concat(this.props.query);
        }
        // if (this.props.partition === 'user'){
        //     this.state.query.splice(0, 0, {option: 'and', condition: 'neq', field: 'role', value: 'Admin session for running function'})
        //
        //     // this.state.query.splice(0, 0, {
        //     //     'condition': 'nin',
        //     //     'field': 'email',
        //     //     'value': '@system.com',
        //     //     'option': 'and'
        //     // });
        //     // this.state.query.splice(0, 0, {option: 'and', condition: 'eq', field: 'partition', value: 'user'});
        // }
    }

    getFields = (filter) => {
        let fields = [...this.fields];
        if (this.props.searchFields){
            this.props.searchFields.forEach(field => {
                if (!fields.includes(field)){
                    fields.push(field);
                }
            });
            fields = fields.sort((a, b)=>{
                return this.props.searchFields.includes(b) - this.props.searchFields.includes(a);
            });
        }

        return <select onChange={(e)=> {
            filter.field = e.target.value;
        }}>
            {fields.map(field => {
                if (!filter.field){
                    filter.field = field;
                }
                return <option value={field}>{field}</option>
            })}
        </select>;
    };

    addSearchFilter = () => {
        let filters = this.state.searchFilters;
        filters.push({
            condition: 'eq',
            field: '',
            value: '',
            option: 'and'
        });
        this.setState({
            searchFilters: filters
        });
    };

    render() {
        return (
            <div className="card">
                <div className="card-header border-0">

                    <Container fluid>
                        <Row>
                            <Col>
                                <h4>{this.props.name}</h4>
                            </Col>
                            <Col className={'text-right'}>
                                <Button onClick={()=>this.onSearch()} className={'ml-2'}>검색</Button>
                                {this.getTopButtons()}
                            </Col>

                        </Row>

                        {this.state.searchFilters.map((filter, index) => {
                            let inputType = 'text';
                            if (filter.inputType){
                                inputType = filter.inputType;
                            }
                            return <Row className={'mt-1'}>
                                <Col>
                                    <Card>
                                        <CardBody>

                                            {this.getFields(filter)}
                                            <select onChange={(e)=>{
                                                filter.condition = e.target.value;
                                            }}>
                                                <option value={'eq'}>==</option>
                                                <option value={'neq'}>!=</option>
                                                <option value={'in'}>in</option>
                                                <option value={'gt'}>{'>'}</option>
                                                <option value={'ge'}>{'>='}</option>
                                                <option value={'ls'}>{'<'}</option>
                                                <option value={'le'}>{'<='}</option>
                                            </select>

                                            <select onChange={(e)=> {
                                                inputType = e.target.value;
                                                filter.inputType = inputType;
                                                this.setState({
                                                    searchFilters: this.state.searchFilters
                                                })
                                            }}>
                                                <option value={'text'}>텍스트</option>
                                                <option value={'date'}>날짜</option>
                                                <option value={'checkbox'}>체크</option>
                                            </select>

                                            <select onChange={(e)=> {
                                                filter.option = e.target.value;
                                                this.setState({
                                                    searchFilters: this.state.searchFilters
                                                })
                                            }}>
                                                <option value={'and'}>AND</option>
                                                <option value={'or'}>OR</option>
                                            </select>

                                            <Button
                                                onClick={()=>{
                                                    let filters = this.state.searchFilters;
                                                    filters.splice(index, 1);
                                                    this.setState({
                                                        searchFilters: filters
                                                    });
                                                }}
                                                className={'btn btn-danger ml-1 mb-1'}> <b>X</b> </Button>

                                            <FormControl
                                                name={'keyword'}
                                                onChange={(e)=>{
                                                    if (inputType === 'date'){
                                                        let timestamp = Date.parse(e.target.value);
                                                        timestamp = timestamp / 1000;
                                                        filter.value = timestamp;
                                                    }else if (inputType === 'text'){
                                                        filter.value = e.target.value;
                                                    }else if (inputType === 'checkbox'){
                                                        filter.value = e.target.checked;
                                                    }
                                                }}
                                                onKeyPress={this.handleKeyPress}
                                                type={inputType}
                                                placeholder={'값을 입력하세요'}
                                                aria-label="Input group example"
                                                aria-describedby="btnGroupAddon"
                                            />


                                        </CardBody>
                                    </Card>

                                </Col>

                            </Row>
                        })}

                        <Row>
                            <Col>
                                <Button className={'btn btn-block btn-secondary mt-1'}
                                        onClick={this.addSearchFilter}>필터 추가 + </Button>
                            </Col>
                        </Row>

                    </Container>

                </div>

                <LoadingOverlay
                    active={this.state.busy}
                    spinner={<BounceLoader />}
                >
                </LoadingOverlay>

                <div className="table-responsive">
                    <table className="table align-items-center table-flush" style={{
                        minHeight: 500,
                    }}>
                        <thead className="thead-light">
                        <tr>
                            {this.headers()}
                            <th scope={'col'}>동작</th>
                        </tr>
                        </thead>
                        <tbody style={{
                            background: this.getBackground(),
                        }}>
                            {this.state.rows.map((row, index) => {
                                return <tr><td>
                                    <FormCheck checked={this.state.checkedIndexes[index]} onChange={(checkbox)=> {
                                        let checkedIndexes = this.state.checkedIndexes;
                                        checkedIndexes[index] = checkbox.target.checked;
                                        this.setState({
                                            checkedIndexes: checkedIndexes
                                        });
                                    }}/>
                                </td>
                                    {row}
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="card-footer py-4">
                    <Button onClick={() => {
                        if (this.startKey){
                            this.appendRows();
                        }
                    }} className={'btn-block btn-info'}>추가 로드</Button>
                </div>

            </div>
        );
    }

}

export default AutoTable;
