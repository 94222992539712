import API from "../server/service/api/API";


class Util{

    static cache = {};

    static timestampToDateString = (unix_timestamp) => {
        let date = new Date(unix_timestamp * 1000);
        let years = date.getFullYear();
        let months = date.getMonth();
        let days = date.getDay();
        let hours = date.getHours();
        let minutes = "0" + date.getMinutes();
        let seconds = "0" + date.getSeconds();

        let formattedTime = years + '-' + months + '-' + days + ' ' + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
        return formattedTime;
    };



    static getProduct = async (item_id) => {
        if (Util.cache[item_id]) {
            return Util.cache[item_id]
        }
        let product = (await API.database.getItem(item_id)).item;
        Util.cache[item_id] = product;
        return product;
    };

    static productCountsToName = async (product_counts) => {
        let name = "";
        if (!product_counts){
            return name;
        }
        for (let product_count of product_counts){
            let product = await Util.getProduct(product_count.product_id);
            if (product){
                let product_name = product.title;
                let count = product_count.count;
                name += product_name + " " + count + "개 ";
            }
        }
        return name;
    };

    static getSplitedProductNames = async (product_counts) => {
        // 박스단위로 상품을 나눕니다.
        let product_names = [];
        let boxes = [
            {'price': 1100, 'max': 12, 'min': 7, 'name': '극소'},
            {'price': 900, 'max': 6, 'min': 0, 'name': '극소'}
        ];
        let box_types = [];
        for (let product_count of product_counts){
            let product = await Util.getProduct(product_count.product_id);
            let product_name = product.title;
            for (let i = 0; i < product_count.count; i++){
                product_names.push(product_name);
            }
        }
        let products_groups = [];
        let product_count = product_names.length;
        let index = 0;
        while (product_count > 0){
            let products_group = {};
            for (let box_size of boxes){
                if (box_size.min < product_count){
                    let group_count = Math.min(box_size.max, product_count);
                    product_count -= group_count;
                    for (let i = 0; i < group_count; i++){
                        if (products_group[product_names[index]]){
                            products_group[product_names[index]] += 1;
                        }else{
                            products_group[product_names[index]] = 1;
                        }
                        index++;
                    }
                    products_groups.push(products_group);
                    box_types.push(box_size);
                    break
                }
            }
        }
        let product_names_groups = [];
        products_groups.forEach((products_group, idx) => {
            let nameString = '';
            Object.keys(products_group).forEach((name, j) => {
                let count = products_group[name];
                nameString += name + " " + count + "개";
                if (j !== Object.keys(products_group).length - 1){
                    nameString += ", ";
                }
            });
            product_names_groups.push({
                'products_name': nameString,
                'box_type': box_types[idx]
            });
        });
        return product_names_groups;
    }
}

export default Util;
