import React, {Component} from "react";
import Container from "react-bootstrap/Container";
import Sidebar from "./sidebar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "react-datepicker/dist/react-datepicker.css";


class Dashboard extends Component {

    static path = '/dashboard';

    state = {
        startDate: null,
        endDate: null,
        managementServiceUsers: null
    };

    render() {
        return (
            <>
                <Sidebar history={this.props.history}/>

                <Breadcrumb>
                    <Breadcrumb.Item active>대시보드</Breadcrumb.Item>
                </Breadcrumb>

                <Container fluid className={'mt-4 mb-4'}>

                </Container>

            </>
        );
    }
}

export default Dashboard;
