import React, { Component } from 'react';
import { Col, Button } from 'reactstrap';


class SignIn extends Component {
    state = {
        email: null,
        password: null,
        code: null
    };

    handleSetText = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    onClickSignIn = () => {
        this.props.onSignIn(this.state.email, this.state.password);
    };

    handleKeyPress = (e) => {
        if (e.key === "Enter") {
            this.onClickSignIn();
        }
    };

    render() {
        return (
            <Col className="align-items-center container text-center col-8 mt-5">
                <Col className="centered">
                    {/*<div className="card shadow border-0">*/}

                        <div className="card-body px-lg-5 py-lg-5">
                            <div className="text-center text-muted mb-4">
                                <h2>관리자 로그인</h2>
                            </div>

                            <form role="form">
                                <div className="form-group mb-3">
                                    <div className="input-group input-group-alternative">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">@이메일</span>
                                        </div>
                                        <input onChange={this.handleSetText} name="email" className="form-control" placeholder="email@example.com" type="email"/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="input-group input-group-alternative">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                비밀번호
                                            </span>
                                        </div>
                                        <input onChange={this.handleSetText} onKeyPress={this.handleKeyPress} name="password" className="form-control" placeholder="*******" type="password"/>
                                    </div>
                                </div>

                                <div className="text-center">
                                    <Button onClick={this.onClickSignIn} type="button" className="btn btn-block btn-primary my-4">관리자 로그인</Button>
                                </div>

                            </form>
                        </div>

                    {/*</div>*/}

                </Col>
            </Col>
        );
    }
}

export default SignIn;
