import React, {Component} from "react";
import Sidebar from "../sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import AutoDetail from "../../../components/autoDetail";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import API from "../../../server/service/api/API";
import Products from "./Products";
import Col from "react-bootstrap/Col";


class FreePriceDetail extends Component{

    static path = '/freeprice/detail/';

    state = {

    };

    constructor(props){
        super(props);
    };

    getProductId = () => {
        // return this.props.match.params.item_id;
        return 'ZRM8NdezXERGQD6FVyvR3u';
    };

    componentDidMount() {

    }

    render() {
        return (
            <>
                <Sidebar history={this.props.history}/>

                <Breadcrumb>
                    <Breadcrumb.Item active>판매 관리</Breadcrumb.Item>
                    <Breadcrumb.Item href={FreePriceDetail.path}>무료 구독가</Breadcrumb.Item>
                    <Breadcrumb.Item href={window.location.href}>상세</Breadcrumb.Item>
                </Breadcrumb>

                <Container fluid className={'mt-4 mb-4'}>

                    <Row>
                        <Col>
                            <AutoDetail
                                name={'상품 정보'}
                                itemId={this.getProductId()}
                                headers={[
                                    {title: '생성일', field: 'creation_date', type: 'date', readOnly: true},
                                    {title: '가격', field: 'price', type: 'number'},
                                    {title: '상품 목록', field: 'product_counts', type: 'list', headers: [
                                            {title: '상품', field: 'product_id', type: 'item',

                                                detail: {
                                                    name: '상품',
                                                    headers: [
                                                        {title: '이름', field: 'name', type: 'string'},
                                                        {title: '제목', field: 'title', type: 'string'},
                                                    ],
                                                },
                                                table: {
                                                    partition: 'new_product',
                                                    headers: [
                                                        {title: '이름', field: 'title', type: 'string'}
                                                    ],
                                                    query: [{condition: 'eq', field: 'visible', value: true, option: 'and'}]
                                                }
                                            },
                                            {title: '개수', field: 'count', type: 'number'}
                                        ]},
                                ]}
                                onSave={(item) => {
                                    if (this.getProductId()){
                                        API.database.updateItem(item.id, item).then(data => {
                                            if (data.success) {
                                                alert("성공!");
                                            }
                                        });
                                    }
                                }}
                            />
                        </Col>
                    </Row>

                </Container>

            </>
        );
    }

}

export default FreePriceDetail;
