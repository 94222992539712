import * as React from "react";
import Button from "react-bootstrap/Button";
import API from "../server/service/api/API";
import Workbook from 'react-excel-workbook';
import Modal from "react-bootstrap/Modal";
import moment from 'moment';

class ExportExcelButton extends React.Component{

    state = {
        downloader: null,
        showModal: false
    };

    componentDidMount() {

    }

    getPartition = () => {
        return this.props.partition;
    };

    doQuery = (partition, query, callback, start_key=null, items=[]) => {
        if (partition === 'user'){
            API.auth.getUsers(start_key).then(response => {
                let _items = items.concat(response.items);
                let end_key = response.end_key;
                if (end_key){
                    this.doQuery(partition, query, callback, end_key, _items);
                }else{
                    callback(_items);
                }
            });
        }else{
            API.database.queryItems(partition, query, 500, false, start_key).then(response => {
                let _items = items.concat(response.items);
                let end_key = response.end_key;
                console.log(JSON.stringify(end_key));
                if (end_key){
                    this.doQuery(partition, query, callback, end_key, _items);
                }else{
                    callback(_items);
                }
            })
        }

    };

    getFileOption = () => {
        return "";
    };

    getLabels = async (items) => {
        let labels = [];
        for (let item of items) {
            let transformedItems = await this.transformItem(item);
            for (let tItem of transformedItems) {
                Object.keys(tItem).forEach(key => {
                    if (!labels.includes(key)) {
                        labels.push(key);
                    }
                })
            }
        }
        return labels;
    };

    transformItem = async (item) => {
        if (this.props.transformItem) {
            return await this.props.transformItem(item);
        }
        return [item];
        //아이템 전처리
    };

    getDownloader = async (items, labels) => {
        alert(items.length + '건이 출력됩니다.');
        let newItems = [];
        for (let item of items) {
            let tItems = await this.transformItem(item);
            for (let item of tItems) {
                let _item = {};
                Object.keys(item).forEach(key => {
                    if (key === "creation_date") {
                        let timestamp = moment.unix(item[key]);
                        let timestring = timestamp.format("YYYY-MM-DD HH:mm:ss");
                        _item[key] = timestring;
                    } else {
                        _item[key] = item[key].toString();
                    }

                });
                newItems.push(_item);
            }
        }
        let now = moment.unix((+new Date()) / 1000).format("YYYY-MM-DD-HHmm");
        return <>
            <Workbook filename={this.getPartition() + this.getFileOption() + now + ".xlsx"}
                      element={<button className="btn btn-block btn-primary">엑셀파일 다운로드</button>}>
                <Workbook.Sheet data={newItems} name="Sheet">
                    {labels.map((label) => {
                        return <Workbook.Column label={label} value={label}/>
                    })}
                </Workbook.Sheet>
            </Workbook>
        </>
    };

    downloadExcelFile = async (query) => {
        if (this.props.items){
            let items = this.props.items;
            let labels = await this.getLabels(items);
            let downloader = await this.getDownloader(items, labels);
            this.setState({
                downloader,
                showModal: true
            });
        }else{
            let partition = this.getPartition();
            this.doQuery(partition, query, async (items) => {
                let labels = await this.getLabels(items);
                let downloader = await this.getDownloader(items, labels);
                this.setState({
                    downloader,
                    showModal: true
                });
            })
        }
    };

    render() {
        let query = this.props.query;
        if (!query){
            query = [];
        }
        return (<>
                <Modal size={'xl'} show={this.state.showModal} onHide={()=> this.setState({showModal:false})}>
                    <Modal.Header closeButton/>
                    <Modal.Body>
                        {this.state.downloader}
                    </Modal.Body>
                </Modal>
                <Button onClick={()=> this.downloadExcelFile(query)} className={'btn btn-success ml-1'}>
                    엑셀
                </Button>

            </>
        );
    }

}

export default ExportExcelButton;
