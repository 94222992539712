
import React, {Component} from "react";
import Sidebar from "../sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import AutoDetail from "../../../components/autoDetail";
import Users from "./users";


class WithdrawalRecordDetail extends Component{

    static path = '/withdrawalrecord/:item_id';

    state = {
        patterned_transactions_view: null
    };

    constructor(props){
        super(props);
    };

    componentDidMount() {

    }

    getId = () => {
        if (this.props.item_id){
            return this.props.item_id;
        }
        if (this.props.match){
            return this.props.match.params.item_id;
        }
    };

    render() {
        return (
            <>
                <Sidebar history={this.props.history}/>

                <Breadcrumb>
                    <Breadcrumb.Item active>회원 관리</Breadcrumb.Item>
                    <Breadcrumb.Item href={Users.path}>탈퇴자 목록</Breadcrumb.Item>
                    <Breadcrumb.Item href={window.location.href}>상세</Breadcrumb.Item>
                </Breadcrumb>

                <Container fluid className={'mt-4 mb-4'}>

                    <Row>
                        <Col className={'col-12 mt-4'}>
                            <AutoDetail
                                name={'탈퇴자 정보'}
                                itemId={this.getId()}
                                headers={[
                                    {title: '', field: 'user', type: 'dict', headers: [
                                            {title: '', field: 'item', type: 'dict', headers: [

                                                {title: '생성일', field: 'creation_date', type: 'date', readOnly: true},
                                                {title: '이름', field: 'name', type: 'string'},
                                                {title: '이메일', field: 'email_addr', type: 'string'},
                                                {title: '성별', field: 'gender', type: 'string', options: ['F', 'M']},
                                                {title: '생일', field: 'birth', type: 'string'},
                                                {title: '핸드폰', field: 'email', type: 'string', readOnly: true},
                                                {title: '기기정보', field: 'device_info', type: 'dict', headers: [
                                                        {title: '웹 브라우저', field: 'web_browser', type: 'string'},
                                                        {title: '사용 함수명', field: 'function_name', type: 'string'}
                                                    ]},
                                                {title: '마케팅 수신 동의', field: 'marketing_agreement', type: 'boolean', readOnly:true}

                                                ]}
                                        ]}
                                ]}
                            />
                        </Col>
                    </Row>

                </Container>

            </>
        );
    }

}

export default WithdrawalRecordDetail;
