


import React, {Component} from "react";
import AutoTable from "../../../components/autoTable";
import Sidebar from "../sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ProductDetail from "./ProductDetail";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import API from "../../../server/service/api/API";


class Products extends Component{

    static path = '/products';

    render() {
        return (
            <>
                <Sidebar history={this.props.history}/>

                <Breadcrumb>
                    <Breadcrumb.Item active>판매 관리</Breadcrumb.Item>
                    <Breadcrumb.Item href={window.location.href}>상품 목록</Breadcrumb.Item>
                </Breadcrumb>

                <Container fluid className={'mt-4 mb-4'}>

                    <Tabs defaultActiveKey="visible">
                        <Tab eventKey="visible" title="노출 상품">

                            <Row>
                                <Col>
                                    <AutoTable
                                        name={'상품 목록'}
                                        partition={'new_product'}
                                        searchFields={['name']}
                                        query={[
                                            {condition: 'eq', field: 'visible', value: true, option: 'and'}
                                        ]}
                                        limit={100}
                                        headers={[
                                            {title: '우선순위', field: 'priority', type: 'number'},
                                            {title: '베스트 상품', field: 'is_best', type: 'boolean'},
                                            {title: '핫 상품', field: 'is_hot', type: 'boolean'},
                                            {title: '노출', field: 'visible', type: 'boolean'},
                                            {title: '노출(스토어)', field: 'visible_store', type: 'boolean'},
                                            {title: '제목', field: 'title', type: 'string'},
                                            {title: '상세', field: 'detail', type: 'string'},
                                            {title: '설명', field: 'description', type: 'textarea'},
                                            {title: '카테고리', field: 'category', type: 'string', options: [
                                                    'dripsso', 'coffeeplease'
                                                ]},
                                            {title: '이름', field: 'name', type: 'string'},
                                            {title: '이미지', field: 'image_id', type: 'image'},
                                            {title: '실물 이미지', field: 'real_image_id', type: 'image'},
                                            {title: '단품가', field: 'unit_price', type: 'number'},
                                            {title: '구독가', field: 'subscription_price', type: 'number'},
                                            {title: '생성일', field: 'creation_date', type: 'date'},
                                        ]}
                                        reverse={true}
                                        onShowDetail={(item) => {
                                            this.props.history.push(ProductDetail.path.split(':')[0] + item.id);
                                        }}
                                        onDeleteItem={(item) => {
                                            if (window.confirm('정말 삭제하시겠습니까?')){
                                                API.database.deleteItem(item.id).then((data)=>{
                                                    if (data.success){
                                                        window.location.reload();
                                                    }
                                                });
                                            }
                                        }}
                                        onClickCreate={() => {
                                            this.props.history.push(ProductDetail.path.split(':')[0]);
                                        }}

                                    />
                                </Col>
                            </Row>

                        </Tab>

                        <Tab eventKey="visible_store" title="스토어 노출 상품">

                            <Row>
                                <Col>
                                    <AutoTable
                                        name={'상품 목록'}
                                        partition={'new_product'}
                                        searchFields={['name']}
                                        query={[
                                            {condition: 'eq', field: 'visible_store', value: true, option: 'and'}
                                        ]}
                                        limit={100}
                                        headers={[
                                            {title: '우선순위', field: 'priority', type: 'number'},
                                            {title: '베스트 상품', field: 'is_best', type: 'boolean'},
                                            {title: '핫 상품', field: 'is_hot', type: 'boolean'},
                                            {title: '노출', field: 'visible', type: 'boolean'},
                                            {title: '노출(스토어)', field: 'visible_store', type: 'boolean'},
                                            {title: '제목', field: 'title', type: 'string'},
                                            {title: '상세', field: 'detail', type: 'string'},
                                            {title: '설명', field: 'description', type: 'textarea'},
                                            {title: '카테고리', field: 'category', type: 'string', options: [
                                                    'dripsso', 'coffeeplease'
                                                ]},
                                            {title: '이름', field: 'name', type: 'string'},
                                            {title: '이미지', field: 'image_id', type: 'image'},
                                            {title: '실물 이미지', field: 'real_image_id', type: 'image'},
                                            {title: '단품가', field: 'unit_price', type: 'number'},
                                            {title: '구독가', field: 'subscription_price', type: 'number'},
                                            {title: '생성일', field: 'creation_date', type: 'date'},
                                        ]}
                                        reverse={true}
                                        onShowDetail={(item) => {
                                            window.open(ProductDetail.path.split(':')[0] + item.id);
                                        }}
                                        onDeleteItem={(item) => {
                                            if (window.confirm('정말 삭제하시겠습니까?')){
                                                API.database.deleteItem(item.id).then((data)=>{
                                                    if (data.success){
                                                        window.location.reload();
                                                    }
                                                });
                                            }
                                        }}
                                        onClickCreate={() => {
                                            this.props.history.push(ProductDetail.path.split(':')[0]);
                                        }}

                                    />
                                </Col>
                            </Row>

                        </Tab>

                        <Tab eventKey="invisible" title="숨김 상품">

                            <Row>
                                <Col>
                                    <AutoTable
                                        name={'상품 목록'}
                                        partition={'new_product'}
                                        searchFields={['name']}
                                        query={[
                                            {condition: 'neq', field: 'visible', value: true, option: 'and'}
                                        ]}
                                        limit={100}
                                        headers={[
                                            {title: '우선순위', field: 'priority', type: 'number'},
                                            {title: '베스트 상품', field: 'is_best', type: 'boolean'},
                                            {title: '핫 상품', field: 'is_hot', type: 'boolean'},
                                            {title: '노출', field: 'visible', type: 'boolean'},
                                            {title: '노출(스토어)', field: 'visible_store', type: 'boolean'},
                                            {title: '제목', field: 'title', type: 'string'},
                                            {title: '상세', field: 'detail', type: 'string'},
                                            {title: '설명', field: 'description', type: 'textarea'},
                                            {title: '카테고리', field: 'category', type: 'string', options: [
                                                    'dripsso', 'coffeeplease'
                                                ]},
                                            {title: '이름', field: 'name', type: 'string'},
                                            {title: '이미지', field: 'image_id', type: 'image'},
                                            {title: '실물 이미지', field: 'real_image_id', type: 'image'},
                                            {title: '단품가', field: 'unit_price', type: 'number'},
                                            {title: '구독가', field: 'subscription_price', type: 'number'},
                                            {title: '생성일', field: 'creation_date', type: 'date'},
                                        ]}
                                        reverse={true}
                                        onShowDetail={(item) => {
                                            this.props.history.push(ProductDetail.path.split(':')[0] + item.id);
                                        }}
                                        onDeleteItem={(item) => {
                                            if (window.confirm('정말 삭제하시겠습니까?')){
                                                API.database.deleteItem(item.id).then((data)=>{
                                                    if (data.success){
                                                        window.location.reload();
                                                    }
                                                });
                                            }
                                        }}
                                        onClickCreate={() => {
                                            this.props.history.push(ProductDetail.path.split(':')[0]);
                                        }}

                                    />
                                </Col>
                            </Row>

                        </Tab>

                    </Tabs>


                </Container>

            </>
        );
    }

}

export default Products;
