import DateTimePicker from 'react-datetime-picker';
import React from "react";


export default function DateTimePickerCustom (props){

    const [value, setValue] = React.useState(props.value);

    const onChange = (datetime) => {
        setValue(datetime);
        if (props.onChange){
            props.onChange(datetime);
        }
    };

    return  <DateTimePicker
        name={props.name}
        onChange={onChange}
        readOnly={props.readOnly}
        value={value}
    />

}
