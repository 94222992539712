


import React, {Component} from "react";
import AutoTable from "../../../components/autoTable";
import Sidebar from "../sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ProductDetail from "./ProductDetail";
import SubscriptionLevelDetail from "./SubscriptionLevelDetail";
import API from "../../../server/service/api/API";
import InventoryDetail from "./InventoryDetail";
import {Badge} from "react-bootstrap";



export default class Inventories extends Component{

    static path = '/inventories';

    render() {
        return (
            <>
                <Sidebar history={this.props.history}/>

                <Breadcrumb>
                    <Breadcrumb.Item active>판매 관리</Breadcrumb.Item>
                    <Breadcrumb.Item href={window.location.href}>재고 목록</Breadcrumb.Item>
                </Breadcrumb>

                <Container fluid className={'mt-4 mb-4'}>

                    <Row>
                        <Col>
                            <AutoTable
                                name={'목록'}
                                partition={'inventory'}
                                searchFields={['name']}
                                query={[]}
                                limit={100}
                                getReloader={(reloader)=>{
                                    this.reloader = reloader;
                                }}

                                buttonActions={[
                                    {
                                        name: '증가 +',
                                        variant: 'success',
                                        handler: (item)=>{
                                            let remain_count = item.remain_count;
                                            API.database.updateItem(item.id, {
                                                remain_count: remain_count + item.increase_count
                                            }).then((response)=>{
                                                if (this.reloader){
                                                    this.reloader();
                                                }
                                            });
                                        }
                                    }, {
                                        name: 'URL',
                                        variant: 'warning',
                                        handler: (item)=>{
                                            window.open(item.url);
                                        }
                                    }
                                ]}
                                headers={[
                                    {title: '충분', field: 'reamin', type: 'custom', transformer: (item)=>{
                                        if (item.remain_count && item.min_count){
                                            if (item.remain_count > item.min_count){
                                                return <Badge variant="success">True</Badge>
                                            }else{
                                                return <Badge variant="warning">False</Badge>
                                            }
                                        }
                                        return <Badge variant="warning">False</Badge>;
                                    }},
                                    {title: '이름', field: 'name', type: 'string'},
                                    {title: '잔여량', field: 'remain_count', type: 'number'},
                                    {title: '증가단위개수', field: 'increase_count', type: 'number'},
                                    {title: '최소잔여량', field: 'min_count', type: 'number'},
                                    {title: '구매처 URL', field: 'url', type: 'string'},
                                    {title: '생성일', field: 'creation_date', type: 'date'},
                                ]}
                                reverse={true}
                                onShowDetail={(item) => {
                                    this.props.history.push(InventoryDetail.path.split(':')[0] + item.id);
                                }}
                                onDeleteItem={(item) => {
                                    if (window.confirm('정말 삭제하시겠습니까?')){
                                        API.database.deleteItem(item.id).then((data)=>{
                                            window.location.reload();
                                        })
                                    }
                                }}
                                onClickCreate={() => {
                                    this.props.history.push(InventoryDetail.path.split(':')[0]);
                                }}

                            />
                        </Col>
                    </Row>
                </Container>

            </>
        );
    }

}
