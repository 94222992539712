import React, {Component} from "react";
import Sidebar from "../sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import AutoDetail from "../../../components/autoDetail";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import API from "../../../server/service/api/API";
import Products from "./Products";
import Col from "react-bootstrap/Col";


class ProductDetail extends Component{

    static path = '/product/detail/:item_id';
    static creation_path = ProductDetail.path.split(':')[0];

    state = {

    };

    constructor(props){
        super(props);
    };

    getProductId = () => {
        return this.props.match.params.item_id;
    };

    componentDidMount() {

    }

    render() {
        return (
            <>
                <Sidebar history={this.props.history}/>

                <Breadcrumb>
                    <Breadcrumb.Item active>판매 관리</Breadcrumb.Item>
                    <Breadcrumb.Item href={Products.path}>상품 목록</Breadcrumb.Item>
                    <Breadcrumb.Item href={window.location.href}>상세</Breadcrumb.Item>
                </Breadcrumb>

                <Container fluid className={'mt-4 mb-4'}>

                    <Row>
                        <Col>
                            <AutoDetail
                                name={'상품 정보'}
                                itemId={this.getProductId()}
                                headers={[
                                    {title: '생성일', field: 'creation_date', type: 'date', readOnly: true},
                                    {title: '베스트 상품', field: 'is_best', type: 'boolean'},
                                    {title: '핫 상품', field: 'is_hot', type: 'boolean'},
                                    {title: '노출', field: 'visible', type: 'boolean'},
                                    {title: '노출(스토어)', field: 'visible_store', type: 'boolean'},
                                    {title: '제목', field: 'title', type: 'string'},
                                    {title: '상세', field: 'detail', type: 'string'},
                                    {title: '설명', field: 'description', type: 'textarea'},
                                    {title: '카테고리', field: 'category', type: 'string', options: [
                                        'dripsso', 'coffeeplease', 'coffeeplease_milktea', 'dripsso_undiluted', 'dripsso_bean'
                                        ]},
                                    {title: '이름', field: 'name', type: 'string'},
                                    {title: '이미지', field: 'image_id', type: 'image'},
                                    {title: '실물 이미지', field: 'real_image_id', type: 'image'},
                                    {title: '단품가', field: 'unit_price', type: 'number'},
                                    {title: '구독가', field: 'subscription_price', type: 'number'},
                                    {title: '우선순위', field: 'priority', type: 'number'},
                                    {title: '성분표시', field: 'ingredient', type: 'list', headers: [
                                            {title: '이름', field: 'name', type: 'string'},
                                            {title: '값', field: 'value', type: 'string'},
                                        ]},
                                    {title: '연결된 재고', field: 'inventory_counts', type: 'list', headers: [
                                            {title: '재고 ID', field: 'inventory_id', type: 'item',
                                                table: {
                                                    partition: 'inventory',
                                                    headers: [
                                                        {title: '이름', field: 'name', type: 'string'},
                                                    ]
                                                },

                                                detail: {
                                                    headers: [
                                                        {title: '이름', field: 'name', type: 'string'},
                                                    ]
                                                }},
                                            {title: '사용량', field: 'count', type: 'number'}
                                        ]},
                                ]}
                                onSave={(item) => {
                                    if (this.getProductId()){
                                        API.database.updateItem(item.id, item).then(data => {
                                            if (data.success) {
                                                alert("성공!");
                                            }
                                        });
                                    }else{
                                        API.database.createItem('new_product', item).then(data => {
                                            if (data.item_id) {
                                                alert("성공!");
                                            }
                                        });
                                    }

                                }}
                            />
                        </Col>
                    </Row>

                </Container>

            </>
        );
    }

}

export default ProductDetail;
