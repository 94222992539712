
import React, {Component} from "react";
import AutoTable from "../../../components/autoTable";
import Sidebar from "../sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import API from "../../../server/service/api/API";


export default class Mileages extends Component{

    static path = '/mileages';

    render() {
        return (
            <>
                <Sidebar history={this.props.history}/>

                <Breadcrumb>
                    <Breadcrumb.Item active>회원 관리</Breadcrumb.Item>
                    <Breadcrumb.Item href={window.location.href}>마일리지 기록</Breadcrumb.Item>
                </Breadcrumb>

                <Container fluid className={'mt-4 mb-4'}>

                    <Row>
                        <Col>
                            <AutoTable
                                name={'마일리지 기록'}
                                partition={'new_mileage_record'}
                                searchFields={['user_id']}
                                searchAsIndex={true}
                                query={[]}
                                join={{
                                    user_id: 'user'
                                }}
                                actions={[
                                    {
                                        name: '테스트',
                                        handler: (itemIds) => {

                                        }
                                    }
                                ]}
                                limit={300}
                                headers={[
                                    {title: '이름', field: 'user.name', type: 'string'},
                                    {title: '이메일', field: 'user.email', type: 'string'},
                                    {title: '적립인 이메일', field: 'buyer_email', type: 'string'},
                                    {title: '잔여 마일리지', field: 'balance', type: 'number'},
                                    {title: '상태', field: 'status', type: 'string'},
                                    {title: '사용', field: 'outbound', type: 'string'},
                                    {title: '적립', field: 'inbound', type: 'string'},
                                    {title: '가입일', field: 'creation_date', type: 'date'},
                                ]}
                                reverse={true}
                            />
                        </Col>
                    </Row>
                </Container>

            </>
        );
    }

}
