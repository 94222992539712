import React, {Component} from "react";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";
import API from "../../server/service/api/API";
import Users from "./member/users";
import WithdrawalRecords from "./member/withdrawalRecords";
import Products from "./sales/Products";
import SubscriptionLevels from "./sales/SubscriptionLevels";
import Orders from "./sales/Orders";
import Subscriptions from "./sales/Subscriptions";
import FreePriceDetail from "./sales/FreePriceDetail";
import Mileages from "./member/mileages";
import Inventories from "./sales/Inventories";
import Reviews from "./sales/Reviews";


class Sidebar extends Component {

    state = {
        me: {}
    };

    logout = () => {
        API.auth.logout().then((succeed) => {
            if (succeed){
                this.props.history.push('/login');
            }
        });
    };


    sideBar = () => {
        const style = {
            height: '100%',
            position: 'fixed',
            zIndex: -1,
            top: 0,
            left: 0,
            backgroundColor: '#fff',
            borderRight: '1px solid #aaa',
            padding: 10,
            overflowX: 'hidden',
            paddingTop: 55
        };
        return (
            <Navbar bg="light" expand="lg">
                <Navbar.Brand href="/dashboard">커피플리즈</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        {/*dashboard/member*/}
                        <NavDropdown title="회원 관리" id="basic-nav-dropdown">
                            <NavDropdown.Item href={Users.path}>회원 목록</NavDropdown.Item>
                            <NavDropdown.Item href={Mileages.path}>마일리지 기록</NavDropdown.Item>
                            <NavDropdown.Item href={WithdrawalRecords.path}>탈퇴 기록</NavDropdown.Item>
                        </NavDropdown>

                        {/*dashboard/sales*/}
                        <NavDropdown title="판매 관리" id="basic-nav-dropdown">
                            <NavDropdown.Item href={Products.path}>상품 목록</NavDropdown.Item>
                            <NavDropdown.Item href={SubscriptionLevels.path}>구독 할인 단계 목록</NavDropdown.Item>
                            <NavDropdown.Item href={Orders.path}>주문 목록</NavDropdown.Item>
                            <NavDropdown.Item href={Subscriptions.path}>정기 구독 목록</NavDropdown.Item>
                            <NavDropdown.Item href={FreePriceDetail.path}>체험판 가격 설정</NavDropdown.Item>

                            <NavDropdown.Item href={Inventories.path}>재고 목록</NavDropdown.Item>
                            <NavDropdown.Item href={Reviews.path}>리뷰 목록</NavDropdown.Item>
                        </NavDropdown>

                    </Nav>

                    <Nav className="mr-right">
                        <NavDropdown title={this.state.me.email} id="basic-nav-dropdown">
                            <NavDropdown.Item onClick={this.logout}>로그아웃</NavDropdown.Item>
                            <NavDropdown.Divider/>
                        </NavDropdown>

                    </Nav>

                </Navbar.Collapse>
            </Navbar>
        )
    };

    checkLogin = () => {
        API.auth.isLogin().then((loggedIn)=> {
            if (!loggedIn){
                this.props.history.push('/login');
            }
        })
    };

    prepareMe = () => {
        API.auth.getMe().then((me) => {
            let canLogin = false;
            let groups = me.groups;
            let allowGroups = ['admin', 'oem_orange'];
            for (let allowGroup of allowGroups){
                if (groups.includes(allowGroup)){
                    canLogin = true;
                }
            }
            if (!canLogin){
                this.props.history.push('/login');
            }
            this.setState({me})
        });
    };

    componentDidMount() {
        this.checkLogin();
        this.prepareMe();
    }

    render() {
        return (
            this.sideBar()
        );
    }
}

export default Sidebar;
