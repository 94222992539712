


import React, {Component} from "react";
import AutoTable from "../../../components/autoTable";
import Sidebar from "../sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ProductDetail from "./ProductDetail";
import SubscriptionLevelDetail from "./SubscriptionLevelDetail";
import API from "../../../server/service/api/API";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import OrderDetail from "./OrderDetail";
import SubscriptionDetail from "./SubscriptionDetail";
import Card from "react-bootstrap/Card";
import CardHeader from "reactstrap/es/CardHeader";
import CardBody from "reactstrap/es/CardBody";
import NextDatePicker from "../../../components/NextDatePicker";
import Time from "../../../util/Time";
import Config from "../../../server/Config";
import SendAlimtalk from "../../../components/SendAlimtalk";
import Util from "../../../util/util";


class Subscriptions extends Component{

    static path = '/subscriptions';

    state = {
        topCards: [],
        nextPaymentDateMax: 0,
        info: {

        },
        showAlimtalk: false,
        phone_numbers: [],
        templates: [],
    };

    componentDidMount() {
        this.fetchProductionCards();
        this.fetchAfterNfree();
        this.fetchAfterNRatio();
    }

    productCache = {};

    getItem = async (itemId) => {
        if (this.productCache[itemId]){
            return this.productCache[itemId];
        }
        let item = await API.database.getItem(itemId);
        this.productCache[itemId] = item;
        return item;
    };

    fetchProductionCards = async () => {
        // let today = new Date();
        // let dd = String(today.getDate()).padStart(2, '0');
        // let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        // let yyyy = today.getFullYear();
        //
        // let todayString = mm + '/' + dd + '/' + yyyy;
        // let startDate = Date.parse(todayString);
        // startDate = +startDate;
        // alert(startDate);

        let subscriptions = API.database.generateItems('subscription', [{
            condition: 'eq',
            field: 'enabled',
            value: true,
            option: 'and'
        }, {
            condition: 'le',
            field: 'next_payment_date',
            value: this.state.nextPaymentDateMax,
            option: 'and'
        }]);

        let products = {};
        for await (let subscription of subscriptions){
            let product_counts = subscription.product_counts;
            for (let product_count of product_counts){
                let product_id = product_count.product_id;
                let count = product_count.count;
                let product = await this.getItem(product_id);
                product = product.item;
                if (product.is_old){
                    for (let product_name in product.names){
                        let count = product.names[product_name];
                        if (products[product_name]){
                            products[product_name] += count
                        }else{
                            products[product_name] = count;
                        }
                    }
                }else{
                    if (products[product.name]){
                        products[product.name] += count;
                    }else{
                        products[product.name] = count;
                    }
                }
            }
        }
        let cards = Object.keys(products).map(name => {
            let count = products[name];
            return <Col xs={12} sm={6} md={4} lg={3} className={'mt-2 mb-2'}>
                <Card>
                    <CardHeader>
                        ({name}) 생산량
                    </CardHeader>
                    <CardBody>
                        {count.toLocaleString()}개
                    </CardBody>
                </Card>
            </Col>;
        });
        this.setState({
            topCards: cards
        })
    };

    fetchAfterNRatio = async () => {
        // min 부터 max 까지의 구독자 유지율 평균
        const min = 14;
        const max = 50;
        const now = + new Date() / 1000;
        let dayCountPair = [];
        let promises = [];
        let allSubscriptionCount = 0;
        let allCancelledCount = 0;
        for (let startDay = min; startDay < max; startDay++){
            let promise = new Promise(async (resolve) => {
                const targetDate = now - startDay * 24 * 60 * 60;
                const targetDateYMD = Time.timestampToDateStringYMD(targetDate);

                let items = await API.database.generateItems('subscription', [
                    {condition: 'eq', field: 'creation_date_ymd', value: targetDateYMD, option: 'and'},
                    {condition: 'eq', field: 'is_trial', value: true, option: 'and'},
                ]);
                for await (let item of items){
                    allSubscriptionCount++;
                }

                let items_cancelled = await API.database.generateItems('cancelled_subscription', [
                    {condition: 'eq', field: 'first_payment_date_ymd', value: targetDateYMD, option: 'and'},
                    {condition: 'eq', field: 'is_trial', value: true, option: 'and'},
                ]);
                for await (let item of items_cancelled){
                    allCancelledCount++;
                }
                resolve();
            });
            promises.push(promise);
        }
        await Promise.all(promises);

        let card = <Col xs={12} sm={6} md={2} className={'mt-2'}>
            <Card>
                <CardHeader>
                    <h7 style={{fontSize: 13}}>{min}일 전 ~ ({max}일 전)</h7>
                </CardHeader>
                <CardBody>
                    <p style={{fontSize: 14}}>{Math.round(allSubscriptionCount * 100 / (allSubscriptionCount + allCancelledCount))}% ({allSubscriptionCount} / {(allSubscriptionCount + allCancelledCount)}) 유지</p>
                </CardBody>
            </Card>
        </Col>;
        this.setState({
            allPercentCard: card
        })
    }

    fetchAfterNfree = async () => {
        // N 일차 무료 구독자 유지율
        let numbers = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];
        const now = + new Date() / 1000;
        let dayCountPair = [];
        let promises = [];
        for (let startDay of numbers){
            let promise = new Promise(async (resolve) => {
                const targetDate = now - startDay * 24 * 60 * 60;
                const targetDateYMD = Time.timestampToDateStringYMD(targetDate);
                const targetDateY_M_D = Time.timestampToDateString(targetDate);

                let subscriptionCount = 0;
                let cancelledCount = 0;
                let items = await API.database.generateItems('subscription', [
                    {condition: 'eq', field: 'creation_date_ymd', value: targetDateYMD, option: 'and'},
                    {condition: 'eq', field: 'is_trial', value: true, option: 'and'},
                ]);
                for await (let item of items){
                    subscriptionCount++;
                }

                let items_cancelled = await API.database.generateItems('cancelled_subscription', [
                    {condition: 'eq', field: 'first_payment_date_ymd', value: targetDateYMD, option: 'and'},
                    {condition: 'eq', field: 'is_trial', value: true, option: 'and'},
                ]);
                for await (let item of items_cancelled){
                    cancelledCount++;
                }
                console.log(subscriptionCount, cancelledCount, targetDateYMD);
                dayCountPair.push({
                    subscriptionCount, cancelledCount, targetDateY_M_D, startDay, targetDate
                });
                resolve();
            });
            promises.push(promise);
        }
        await Promise.all(promises);
        dayCountPair.sort((a, b) => {
            return b.targetDate - a.targetDate;
        });

        let afterNCards = [];
        for (let day_count of dayCountPair){
            let subscriptionCount = day_count.subscriptionCount;
            let cancelledCount = day_count.cancelledCount;
            let targetDateY_M_D = day_count.targetDateY_M_D;
            let startDay = day_count.startDay;
            let percent = Math.round((subscriptionCount / (subscriptionCount + cancelledCount)) * 100);
            let card = <Col xs={12} sm={6} md={2} className={'mt-2'}>
                <Card>
                    <CardHeader>
                        <h7 style={{fontSize: 13}}>{targetDateY_M_D} ({startDay}일 전)</h7>
                    </CardHeader>
                    <CardBody>
                        <p style={{fontSize: 14}}>{percent}% ({subscriptionCount} / {(subscriptionCount + cancelledCount)}) 유지</p>
                    </CardBody>
                </Card>

            </Col>;
            afterNCards.push(card);
        }
        this.setState({
            afterNCards
        })
    };

    render() {
        let orderHeaders = [
            {title: '구독중', field: 'enabled', type: 'boolean'},
            {title: '다음결제일', field: 'next_payment_date', type: 'date'},
            {title: '주기(주)', field: 'weeks', type: 'number'},
            {title: '주문자', field: 'shipping_info.buyer_name', type: 'string'},
            {title: '핸드폰', field: 'shipping_info.buyer_tel', type: 'string'},
            {title: '체험판', field: 'is_trial', type: 'boolean'},
            {title: '이름', field: 'product_counts', type: 'transform', transformer: async (product_counts)=>{
                let value = await Util.productCountsToName(product_counts);
                return <div>{value}</div>;
                }},
            {title: '주문수', field: 'order_count', type: 'number'},
            {title: '생성일', field: 'creation_date', type: 'date'},
        ];

        let actions = [
            {
                name: '선택 삭제',
                handler: async (item_ids) => {
                    if (!window.confirm("정말 삭제하시겠습니까?")){
                        return;
                    }
                    let response = await API.database.batchDeleteItems(item_ids);
                    window.location.reload();
                }
            }, {
                name: '구독 중 변경',
                handler: async (item_ids) => {
                    let pairs = {};
                    for (let item_id of item_ids){
                        pairs[item_id] = {
                            enabled: true
                        }
                    }
                    let response = await API.database.batchUpdateItems(pairs);
                    window.location.reload();
                }
            }, {
                name: '구독 중지',
                handler: async (item_ids) => {
                    let pairs = {};
                    for (let item_id of item_ids){
                        pairs[item_id] = {
                            enabled: false
                        }
                    }
                    let response = await API.database.batchUpdateItems(pairs);
                    window.location.reload();
                }
            }, {
                name: '선택 알림톡 전송', itemHandler: async (subscriptions) => {
                    let phone_numbers = subscriptions.map(subscription => {
                        return subscription.shipping_info.buyer_tel;
                    });

                    const getTemplates = async () => {
                        let templates = await API.logic.runFunction(Config.functionName, {
                            cmd: 'notification.get_templates'
                        });
                        return templates;
                    };
                    const templates = await getTemplates();

                    this.setState({
                        showAlimtalk: true,
                        templates, phone_numbers
                    })

                }
            }, {
                name: '1000원 마일리지 충전',
                itemHandler: async (items) => {
                    let pairs = {};
                    for (let item of items){
                        let mileage = item.user.mileage;
                        if (!mileage){
                            mileage = 0;
                        }
                        mileage += 1000;
                        pairs[item.user_id] = {
                            mileage: mileage
                        }
                    }
                    let response = await API.database.batchUpdateItems(pairs);
                    window.location.reload();
                }
            }
       ];


        return (
            <>
                <Sidebar history={this.props.history}/>

                <Breadcrumb>
                    <Breadcrumb.Item active>판매 관리</Breadcrumb.Item>
                    <Breadcrumb.Item href={window.location.href}>정기 구독 목록</Breadcrumb.Item>
                </Breadcrumb>

                <Container fluid className={'mt-4 mb-4'}>

                    <SendAlimtalk show={this.state.showAlimtalk}
                                  phone_numbers={this.state.phone_numbers}
                                  templates={this.state.templates}
                                  onClose={()=>{
                                      this.setState({
                                          showAlimtalk: false
                                      })
                                  }}/>

                    <Row className={'m-2'}>
                        <Col xs={12} md={6}>
                            <h5>생산량 예측</h5>
                            <NextDatePicker next_payment_date={Date.now()} onChange={(next_payment_date)=>{
                                this.setState({
                                    nextPaymentDateMax: next_payment_date
                                }, ()=>{
                                    this.fetchProductionCards();
                                })
                            }}/>
                        </Col>


                    </Row>

                    <Row >
                        <Col xs={12} className={'m-2'}>
                            <h6>체험판 유지율</h6>
                        </Col>
                        {this.state.afterNCards}
                    </Row>

                    <Row>
                        <Col xs={12} className={'m-2'}>
                            <h6>전체 유지율</h6>
                        </Col>
                        {this.state.allPercentCard}
                    </Row>

                    <Row>
                        {this.state.topCards}
                    </Row>

                    <Tabs defaultActiveKey="standby" className={'mt-4'}>
                        <Tab eventKey="standby" title="구독중">
                            <Row>
                                <Col>
                                    <AutoTable
                                        name={'정기 구독 목록'}
                                        partition={'subscription'}
                                        headers={orderHeaders}
                                        query={[
                                            {condition: 'eq', field: 'enabled', value: true, option: 'and'}
                                        ]}
                                        reverse={true}
                                        onShowDetail={(item) => {
                                            this.props.history.push(SubscriptionDetail.path.split(':')[0] + item.id);
                                        }}
                                        actions={actions}
                                        onClickCreate={() => {
                                            this.props.history.push(SubscriptionDetail.path.split(':')[0]);
                                        }}
                                        join={{
                                            user_id: 'user'
                                        }}

                                    />
                                </Col>
                            </Row>
                        </Tab>

                        <Tab eventKey="shipping" title="구독중지">
                            <Row>
                                <Col>
                                    <AutoTable
                                        name={'중지된 정기 구독 목록'}
                                        partition={'subscription'}
                                        headers={orderHeaders}
                                        query={[
                                            {condition: 'eq', field: 'enabled', value: false, option: 'and'}
                                        ]}
                                        reverse={true}
                                        onShowDetail={(item) => {
                                            this.props.history.push(SubscriptionDetail.path.split(':')[0] + item.id);
                                        }}
                                        actions={actions}
                                        join={{
                                            user_id: 'user'
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Tab>

                        <Tab eventKey="cancelled" title="구독해지">
                            <Row>
                                <Col>
                                    <AutoTable
                                        name={'해지된 정기 구독 목록'}
                                        partition={'cancelled_subscription'}
                                        headers={orderHeaders.concat([{title: '이유', field: 'reason', type: 'string'}])}
                                        query={[

                                        ]}
                                        reverse={true}
                                        onShowDetail={(item) => {
                                            this.props.history.push(SubscriptionDetail.path.split(':')[0] + item.id);
                                        }}
                                        actions={actions}
                                        join={{
                                            user_id: 'user'
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Tab>


                    </Tabs>

                </Container>

            </>
        );
    }


}

export default Subscriptions;
