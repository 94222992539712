


import React, {Component} from "react";
import AutoTable from "../../../components/autoTable";
import Sidebar from "../sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ProductDetail from "./ProductDetail";
import SubscriptionLevelDetail from "./SubscriptionLevelDetail";
import API from "../../../server/service/api/API";


class SubscriptionLevels extends Component{

    static path = '/subscriptionlevels';

    render() {
        return (
            <>
                <Sidebar history={this.props.history}/>

                <Breadcrumb>
                    <Breadcrumb.Item active>판매 관리</Breadcrumb.Item>
                    <Breadcrumb.Item href={window.location.href}>구독 할인 단계 목록</Breadcrumb.Item>
                </Breadcrumb>

                <Container fluid className={'mt-4 mb-4'}>

                    <Row>
                        <Col>
                            <AutoTable
                                name={'구독 할인 단계 목록'}
                                partition={'subscription_level'}
                                searchFields={['name']}
                                query={[]}
                                limit={100}
                                headers={[
                                    {title: '우선순위', field: 'priority', type: 'number'},
                                    {title: '제목', field: 'title', type: 'string'},
                                    {title: '주문수', field: 'order_count', type: 'number'},
                                    {title: '할인율 소수점', field: 'discount_ratio', type: 'number'},
                                    {title: '생성일', field: 'creation_date', type: 'date'},
                                ]}
                                reverse={true}
                                onShowDetail={(item) => {
                                    this.props.history.push(SubscriptionLevelDetail.path.split(':')[0] + item.id);
                                }}
                                onDeleteItem={(item) => {
                                    if (window.confirm('정말 삭제하시겠습니까?')){
                                        API.database.deleteItem(item.id).then((data)=>{
                                            window.location.reload();
                                        })
                                    }
                                }}
                                onClickCreate={() => {
                                    this.props.history.push(SubscriptionLevelDetail.path.split(':')[0]);
                                }}

                            />
                        </Col>
                    </Row>
                </Container>

            </>
        );
    }

}

export default SubscriptionLevels;
