import React, { useState } from "react";
import * as XLSX from "xlsx";
import {Card, Col, Row} from "react-bootstrap";
import CardBody from "reactstrap/es/CardBody";
import CardHeader from "reactstrap/es/CardHeader";

class ExcelToJson extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            file: "",
        };
    }

    handleClick(e) {
        this.refs.fileUploader.click();
    }

    filePathset(e) {
        e.stopPropagation();
        e.preventDefault();
        var file = e.target.files[0];
        console.log(file);
        this.setState({ file });

        console.log(this.state.file);
    }

    readFile() {
        var f = this.state.file;
        var name = f.name;
        const reader = new FileReader();
        reader.onload = (evt) => {
            // evt = on_file_select event
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            /* Update state */
            let json = this.convertToJson(data);
            this.props.callback(json);
        };
        reader.readAsBinaryString(f);
    }

    convertToJson(csv) {
        var lines = csv.split("\n");

        var result = [];

        var headers = lines[0].split(",");

        for (var i = 1; i < lines.length; i++) {
            var obj = {};
            var currentline = lines[i].split(",");

            for (var j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentline[j];
            }

            result.push(obj);
        }

        //return result; //JavaScript object
        return result; //JSON
    }

    render() {
        return (
            <Card>
                <CardHeader>
                    송장 엑셀 등록 (CJ 대한통운)
                </CardHeader>

                <CardBody>
                    <Row>
                        <Col>
                            <input
                                type="file"
                                id="file"
                                ref="fileUploader"
                                onChange={this.filePathset.bind(this)}
                            />
                        </Col>
                        <Col>
                            <button
                                className={'btn btn-primary'}
                                onClick={() => {
                                    this.readFile();
                                }}
                            >
                                송장 등록하기
                            </button>
                        </Col>
                    </Row>
                </CardBody>

            </Card>
        );
    }
}

export default ExcelToJson;