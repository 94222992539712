import React from 'react';
import {Col, Row, Container, Modal, ModalBody, ModalFooter, Button} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import API from "../server/service/api/API";
import Config from "../server/Config";


const SendAlimtalk = (props) => {

    const [targetTemplate, setTargetTemplate] = React.useState({});
    const [variable_pairs, set_variable_pairs] = React.useState({});

    const getPhoneNumbers = () => {
        return props.phone_numbers;
    };

    const titleOptions = (templates) => {
        templates = [{}].concat(templates);
        return <select onChange={(e)=>{
            let title = e.target.value;
            for (let template of templates){
                if (template.title === title){
                    setTargetTemplate(template);
                }
            }
        }}>{templates.map(template => {
            return <option value={template.title}>
                {template.title}
            </option>
        })}</select>;
    };

    const variables = (template) => {
        if (template.variables){
            return template.variables.map(variable => {
                return <Row className={'mb-2'}>
                    <Col xs={12}>
                        <h6>{variable}</h6>
                    </Col>
                    <Col xs={12}>
                        <textarea style={{width:'100%'}} rows={2} onChange={(e)=>{
                            variable_pairs[variable] =  e.target.value;
                            set_variable_pairs(variable_pairs);
                        }}/>
                    </Col>
                </Row>
            });
        }
        return null;
    };

    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onClose}
            >
                <ModalHeader>
                    {titleOptions(props.templates)}
                </ModalHeader>
                <ModalBody>
                    <Container>

                        <Row className={'mb-3'}>
                            <Col>
                                <h6>내용</h6>
                                <text>{targetTemplate.content}</text>
                            </Col>
                        </Row>

                        {variables(targetTemplate)}


                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button
                        className={'btn btn-primary'}
                        onClick={async ()=>{
                            let content = targetTemplate.content;
                            for (let variable in variable_pairs){
                                let value = variable_pairs[variable];
                                while (content.includes('#{' + variable + '}')){
                                    content = content.replace('#{' + variable + '}', value);
                                }
                            }
                            if (window.confirm(content + "\n\n위와 같이 총 " + props.phone_numbers.length + "명의 사용자에게 전송하시겠습니까?")){
                                let response = await API.logic.runFunction(Config.functionName, {
                                    cmd: 'notification.send_alimtalk',
                                    phone_numbers: props.phone_numbers,
                                    template: targetTemplate,
                                    variable_pairs: variable_pairs
                                });
                                alert(JSON.stringify(response));
                            }
                        }}
                    >보내기</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};


export default SendAlimtalk;