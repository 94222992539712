import React, {Component} from "react";
import Sidebar from "../sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import AutoDetail from "../../../components/autoDetail";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import API from "../../../server/service/api/API";
import Products from "./Products";
import Col from "react-bootstrap/Col";
import SubscriptionLevels from "./SubscriptionLevels";


class SubscriptionLevelDetail extends Component{

    static path = '/subscriptionlevel/detail/:item_id';
    static creation_path = SubscriptionLevelDetail.path.split(':')[0];

    state = {

    };

    constructor(props){
        super(props);
    };

    getProductId = () => {
        return this.props.match.params.item_id;
    };

    componentDidMount() {

    }

    render() {
        return (
            <>
                <Sidebar history={this.props.history}/>

                <Breadcrumb>
                    <Breadcrumb.Item active>판매 관리</Breadcrumb.Item>
                    <Breadcrumb.Item href={SubscriptionLevels.path}>구독 할인 단계 목록</Breadcrumb.Item>
                    <Breadcrumb.Item href={window.location.href}>상세</Breadcrumb.Item>
                </Breadcrumb>

                <Container fluid className={'mt-4 mb-4'}>

                    <Row>
                        <Col>
                            <AutoDetail
                                name={'정보'}
                                itemId={this.getProductId()}
                                headers={[
                                    {title: '생성일', field: 'creation_date', type: 'date', readOnly: true},
                                    {title: '우선순위', field: 'priority', type: 'number'},
                                    {title: '제목', field: 'title', type: 'string'},
                                    {title: '주문수', field: 'order_count', type: 'number'},
                                    {title: '할인율 소수점', field: 'discount_ratio', type: 'number'},
                                ]}
                                onSave={(item) => {
                                    if (this.getProductId()){
                                        API.database.updateItem(item.id, item).then(data => {
                                            if (data.success) {
                                                alert("성공!");
                                            }
                                        });
                                    }else{
                                        API.database.createItem('subscription_level', item).then(data => {
                                            if (data.item_id) {
                                                alert("성공!");
                                            }
                                        });
                                    }

                                }}
                            />
                        </Col>
                    </Row>

                </Container>

            </>
        );
    }

}

export default SubscriptionLevelDetail;
